<template>
  <section class="popUpApply" id="applyLink">
    <div class="popUpApply_de-emphasize" v-on:click="goBack" ></div>
    <div class="popUpApplyWrapper">
      <div v-if="popupState === 'form'">
        <header class="popUpApply_header">
          <MoleculeBackButton @goBack="goBack" />
          <AtomTextHeading :level="2" class="popUpApply_job_heading" color="brand-primary">
           <span class="u-text-visuallyHidden">Solliciteer voor: </span> {{ job.titel }}
          </AtomTextHeading>
          <article class="popUpApply_job_details">
            <AtomTextBody class="job_details_typeOfWork" isSmall>
              Werkplek: {{ job.werkplek }}
            </AtomTextBody>

            <AtomTextBody class="job_details_contractHours" isSmall>
              Contract: {{ job.deeltijdfactor }}
            </AtomTextBody>
          </article>
        </header>

        <form
          class="popUpApply_form"
          id="solliciteerForm"
          @submit.prevent="postForm"
        >
          <AtomFormInputWrapper
            id="solliciteerFormWrapper"
            class="popUpApply_form_inputWrapper"
          >
            <AtomFormInputText
              class="popUpApply_form_inputApply"
              v-model="formData.name"
              is-required
              label="Naam"
              name="name"
              placeholder="John doe"
            />

            <AtomFormInputText
              class="popUpApply_form_inputApply"
              v-model="formData.address"
              is-required
              label="Adres"
              name="address"
              placeholder="Straatnaam en huisnummer"
            />

            <AtomFormInputText
              class="popUpApply_form_inputApply"
              v-model="formData.city"
              is-required
              label="Woonplaats"
              name="city"
              placeholder="Stad"
            />

            <AtomFormInputText
              class="popUpApply_form_inputApply"
              v-model="formData.email_address"
              is-required
              is-email
              label="Emailadres"
              name="email_address"
              placeholder="jouw@e-mailadres.nl"
            />

            <AtomFormInputText
              class="popUpApply_form_inputApply"
              v-model="formData.telephone_number"
              is-required
              is-number
              label="Telefoonnummer"
              name="telephone_number"
              placeholder="06 12345678"
            />

            <AtomFormInputText
              class="popUpApply_form_inputApply disabled"
              label="Functie"
              name="job_title"
              is-disabled
              :value="formData.job_title"
            />

            <AtomFormInputText
              class="popUpApply_form_inputApply"
              v-model="formData.motivation"
              is-required
              is-textarea
              label="Motivatie"
              name="motivation"
              placeholder="Schrijf hier je motivatie..."
            />
          </AtomFormInputWrapper>

          <label class="popUpApply_form_checkboxHolder">
            <AtomFormInputCheckbox
              name="privacy"
              class="form_checkboxHolder_checkbox"
              v-model="formData.privacy"
              style="display: inline-block"
            />
            <AtomTextBody class="form_checkboxHolder_text" isInline>
              Ik ga akkoord met de <a target="_blank" href="https://www.werkenbijlidl.nl/privacy-verklaring">privacyvoorwaarden</a>
            </AtomTextBody>
          </label>

          <div v-if="formError" class="error-container">
            <atom-text-body is-bold color="red">
              {{ formError }}
            </atom-text-body>
          </div>

          <div v-if="formSuccess" class="success-container">
            <atom-text-body color="green">
              {{ formSuccess }}
            </atom-text-body>
          </div>

          <AtomButton
            class="popUpApply_form_button"
            type="submit"
            :is-disabled="!formData.privacy || !canSubmit"
          >
            Solliciteer direct
          </AtomButton>
        </form>

      </div>
      <div v-else-if="popupState === 'succes'">
        <header class="popUpApply_header">
          <MoleculeBackButton @goBack="goBack" />
        </header>
        <div class="popUpApply-succes-content">
          <AtomTextHeading :level="2" is-large color="brand-primary">
            Jouw sollicitatie is succesvol verstuurd!
          </AtomTextHeading>
          <AtomTextBody is-bold is-center color="grey-darker">
            We nemen zo snel mogelijk contact met je op zodra we jouw sollicitatie in behandeling hebben genomen.
          </AtomTextBody>

          <AtomButton
            class="popUpApply_form_button"
            v-on:click.native="goBack"
          >
            Terug
          </AtomButton>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import AtomTextBody from "../atoms/AtomTextBody";
import AtomIcon from "../atoms/AtomIcon";
import AtomTextHeading from "../atoms/AtomTextHeading";
import AtomButton from "../atoms/AtomButton";
import AtomBullets from "../atoms/AtomBullets";
import MoleculeBackButton from "../molecules/MoleculeBackButton";
import AtomFormSelectionWrapper from "../atoms/helpers/AtomFormSelectionWrapper";
import AtomFormInputWrapper from "../atoms/helpers/AtomFormInputWrapper";
import AtomFormInputText from "../atoms/AtomFormInputText";
import AtomFormInputCheckbox from "../atoms/AtomFormInputCheckbox";

export default {
  name: "popUpApply",
  components: {
    AtomFormInputCheckbox,
    AtomFormInputText,
    AtomFormInputWrapper,
    AtomFormSelectionWrapper,
    MoleculeBackButton,
    AtomBullets,
    AtomButton,
    AtomTextHeading,
    AtomIcon,
    AtomTextBody,
  },
  data: function () {
    return {
      canSubmit: true,
      formError: null,
      formSuccess: null,
      popupState: 'form',
      formData: {
        name: "",
        address: "",
        city: "",
        email_address: "",
        job_title: this.job.titel,
        telephone_number: "",
        motivation: "",
        privacy: false,
      },
    };
  },
  props: {
    title: {
      type: String,
      default: "Add a title",
    },
    job: {
      type: Object,
    },
  },
  methods: {
    goBack(event) {
      this.$emit("goBack", false);
    },
    postForm() {
      if (this.canSubmit) {
        this.formError = null;
        this.canSubmit = false;
        let newData = this.formData;
        newData.email_destination = this.job.emaildestination.replace(
          "mailto:",
          ""
        );
        delete newData.privacy;

        this.axios
          .post(
            "https://www.lidlwerkt.nl/app_jobs/index.php?action=apply",
            newData
          )
          .then((response) => {
            this.formSuccess = "Uw sollicitatie is verstuurd!"

              this.popupState = 'succes'

            this.$gtag.event('user_send_apply', {
              'event_category' : 'user_events',
              'event_label' : `succes: ${this.job.titel} - to: ${newData.email_destination}`,
              'value' : `1`
            })
          })
          .catch((err) => {
            this.formError = err?.response?.data?.error;
            if (!this.formError && err.message) {
              this.formError = err.message
            } else {
              this.formError = 'Er heeft zich een onverwachte fout voorgedaan. Als deze fout blijft voorkomen neem dan contact op met werken@lidl.nl.'
            }

            this.canSubmit = true;

            this.$gtag.event('user_send_apply_failed', {
              'event_category' : 'user_events',
              'event_label' : `failed: ${err} - ${this.job.titel} - to: ${newData.email_destination}`,
              'value' : `2`
            })
          });
      }
    },
  },
  created () {
    this.popupState = 'form'
  }
};
</script>

<style lang="scss" scoped>
#applyLink {
  scroll-margin-top: 64px; // when user clicks on 'apply', the scroll will have a litle padding-top thing.
}

::v-deep .disabled input {
  color: $color-palette-black;
}
.popUpApply_form_checkboxHolder {
  width: 100%;
  display: flex;
  align-items: center;
  align-self: center;

  .popUpApply_form_inputWrapper {
    margin-bottom: 0;
    margin-right: 8px;

    @media (min-width: 900px) {
      margin-bottom: 0; // it's already done by the last item in the form
    }
  }
}
.popUpApply_form_checkboxHolder p {
  padding: 0 10px;
  text-transform: uppercase;
  color: $color-palette-grey;
  font-size: small;
  max-width: 75%;
}

.form_checkboxHolder_text {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
}

.popUpApply {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10;
  //background-color: $color-palette-white;
  font-family: "Lidl Font Pro", sans-serif;

  @media (min-width: 900px) {
  //  position: absolute;
  //  width: 876px;
  //  max-width: 876px;
  //  height: 792px;
  //  top: 199px;
  //  top: 22vh;
  //  left: 282px;
  //  right: 282px;
  //  // margin-bottom: 10vh;
  }
}

.popUpApply_de-emphasize {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.2);
  z-index: -1;
}

.popUpApplyWrapper {
  z-index: 2;
  width: 100%;
  max-width: 800px;
  height: calc(100vh + 10px);
  margin: 0 auto;
  overflow: scroll;
  padding: 24px; // padding-right is different per sub-element, so that will be defined per individual element.
  background-color: $color-palette-white;

  @media (min-width: 900px) {
    max-height: 90vh;
    margin-top: 5vh;
    //max-width: 100%;
    //display: flex;
    //flex-direction: column;
    //align-items: center;
    //padding: 24px 51px 40px 85px;
    //height: fit-content;
  }
}

// HEADER
.popUpApply_header {
  margin-right: 18px;
  margin-bottom: 16px;
  width: fit-content;

  @media (min-width: 900px) {
    margin-right: 0;
    margin-bottom: 31px;
    width: 100%;
  }
}

// JOB HEADING
.popUpApply_job_heading {
  color: $color-brand-primary;
  font-weight: 600;
  font-size: 21px;
  line-height: 25px;
  letter-spacing: 0.574219px;
  margin-bottom: 16px;

  @media (min-width: 900px) {
    margin-bottom: 8px;
  }
}

// JOB DETAILS
.popUpApply_job_details {
  width: 325px;

  @media (min-width: 900px) {
    width: fit-content;
  }
}

.job_details_typeOfWork,
.job_details_contractHours {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.410156px;
  color: #8b979e;
}

// FORM
.popUpApply_form {
  width: 100%;
  max-width: 326px;
  @media (min-width: 900px) {
    max-width: 100%;
  }
}

.popUpApply_form_inputApply {
  margin-bottom: 16px;
  @media (min-width: 900px) {
    width: 362px;
    padding-right: 16px;

    &:last-of-type {
      width: 740px;
      padding-right: 32px; // twice the amount of the ones above
    }
  }
}

::v-deep .popUpApply_form_inputApply input,
::v-deep .popUpApply_form_inputApply textarea {
  border: 1px solid $color-brand-example;
  border-radius: 2px;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #8b979e;
}

::v-deep .popUpApply_form_inputApply textarea {
  resize: none;
}

::v-deep input[type="number"]::-webkit-inner-spin-button,
::v-deep input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.popUpApply_form_button {
  width: 100%;
  border-radius: 3px;
  background-color: #fff000;
  margin: $inuit-global-spacing-unit-small 0;
  color: $color-brand-primary-darker;

  @media (min-width: 900px) {
    margin: 32px 0 0 0;
    width: 198px;
    border-radius: 2px;
  }
}

.form_checkboxHolder_checkbox {
  margin-right: 8px;
}

// EXTRA
.pusher {
  height: 100px;
  width: 100%;
}

.pusherSmall {
  height: 55px;
}

.popUpApply-succes-content {
  max-width: 400px;
  padding: 0 0.5em;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  h2 {
    margin-bottom: 8px;
  }
}

.error-container {
  margin-top: 16px;
}
</style>
