<template>
  <div class="selection-wrapper">
    <AtomIcon
      :class="{ spin: isOpen }"
      class="dropDownIcon"
      color="brand-primary"
      type="arrow-down"
      v-if="hasDrop"
    ></AtomIcon>
    <!--Waarom kan dit niet in de Atom icon-->
    <!--    :class="{ spin: isOpen }"-->

    <label
      :class="{
        'selection-wrapper__label--has-error': hasError,
        'selection-wrapper__label--has-success': hasSuccess,
      }"
      :for="id"
      class="selection-wrapper__label"
      v-if="label"
      v-on:click="$emit('labelClick', $event)"
    >
      {{ label }}
    </label>

    <span
      :class="{
        'selection-wrapper__input--circle': isCircle,
        'selection-wrapper__input--has-error': hasError,
        'selection-wrapper__input--has-success': hasSuccess,
        'selection-wrapper__input--has-label': label,
      }"
      class="selection-wrapper__input"
    >
      <slot></slot>
    </span>
  </div>
</template>

<script>
import { formPropTypes } from "@/prop-types/form";
import AtomIcon from "../AtomIcon";

export default {
  name: "AtomFormSelectionWrapper",
  components: { AtomIcon },
  props: {
    id: formPropTypes.inputId,
    label: formPropTypes.inputLabel,
    hasError: formPropTypes.inputHasError,
    hasSuccess: formPropTypes.inputHasSuccess,
    isCircle: {
      type: Boolean,
    },
    hasDrop: {
      type: Boolean,
      default: false,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
// For /deep/ usage see: https://vue-loader.vuejs.org/guide/scoped-css.html#deep-selectors

.selection-wrapper {
  display: flex;
  justify-content: space-between;
}

.selection-wrapper__input {
  display: block;
  flex: 0 0 16px;
  height: 16px;
  position: relative;
  width: 16px;

  &::before {
    @include position-cover();

    background-color: $color-palette-white;
    border: 2px solid $color-palette-grey;
    border-radius: $global-radius;
    content: "";
    z-index: 0;
  }

  ::v-deep > input {
    cursor: pointer;
    left: 0;
    opacity: 0.01;
    position: absolute;
    top: 0;
    z-index: 2;
    height: 16px;
    width: 16px;
  }
}

.popUpFilter_form_inputWrapper .selection-wrapper {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: center;
}

.popUpFilter_form_inputWrapper .selection-wrapper__label {
  font-size: 16px;
  line-height: 19px;
  color: #8b979e;

    @media (min-width: 900px) {
      color: #0A0A0A;
    }
}

.workDrop .selection-wrapper {
    display: flex;
    flex-direction: row-reverse;
  }

.dropDownIcon {
  /*position: absolute;*/
  //margin-left: -25px;
}

.spin {
  transform: rotate(180deg);
}

.selection-wrapper__input--circle {
  &::before {
    border-radius: 50%;
  }
}

.selection-wrapper__input--has-label {
  // margin-left: $global-spacing-unit-tiny;
  // top: 4px;
}

.selection-wrapper__input--has-error {
  &::before {
    background-color: $color-palette-white;
    border-color: $color-status-error;
  }
}

.selection-wrapper__input--has-success {
  &::before {
    background-color: $color-palette-white;
    border-color: $color-brand-primary;
  }
}

.selection-wrapper__label {
  @include font-size(14px);
  color: $color-brand-primary-lighter;
  display: block;
  margin-left: 8px;
}

.selection-wrapper__label--has-error {
  color: $color-status-error;
}

.selection-wrapper__label--has-success {
  color: $color-brand-primary;
}
</style>
