<template>
  <section>
    <article class="information_text_part">
      <AtomTextHeading
        isSmall
        :level="3"
        color="brand-primary-darker"
        class="text_part_heading"
      >
        De baan
      </AtomTextHeading>
      <AtomTextBody color="brand-primary-lighter" class="text_part_description">
        <div class="description_list" v-html="data.baanomschrijving">
          {{ data.baanomschrijving }}
        </div>
      </AtomTextBody>
    </article>
    <article class="information_text_part">
      <AtomTextHeading
        isSmall
        :level="3"
        color="brand-primary-darker"
        class="text_part_heading"
        > Jouw profiel
      </AtomTextHeading>
      <AtomTextBody color="brand-primary-lighter"  class="text_part_description">
        <div class="description_list" v-html="data.profiel">
          {{ data.profiel }}
        </div>
      </AtomTextBody>
    </article>
    <article class="information_text_part">
      <AtomTextHeading
        isSmall
        :level="3"
        color="brand-primary-darker"
        class="text_part_heading"
        >Wij bieden
      </AtomTextHeading>
      <AtomTextBody color="brand-primary-lighter"  class="text_part_description">
        <div class="description_list" v-html="data.wij_bieden">
          {{ data.wij_bieden }}
        </div>
      </AtomTextBody>
    </article>

    <!--    <ul class="list-ul" >-->

    <!--            <li-->
    <!--                    class="list-li"-->
    <!--                    v-html="rawHtml"-->
    <!--                    v-for="bullets in data.baanomschrijving"-->
    <!--            >-->
    <!--               <span> {{bullets}} </span>-->
    <!--            </li>-->
    <!--    </ul>-->
  </section>
</template>

<script>
import AtomTextHeading from "./AtomTextHeading";
import AtomTextBody from "./AtomTextBody";
import AtomIcon from "./AtomIcon";
export default {
  name: "AtomBullets",
  components: { AtomIcon, AtomTextBody, AtomTextHeading },
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.information_text_part {
    &:last-of-type {
       .text_part_heading {
      font-weight: 600;

}
    }
}
.text_part_heading, .text_part_description {
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.410156px;
  color: #000000;
}

.text_part_heading {
      font-weight: 600;
      margin-bottom: 9px;
}

.text_part_description {
    font-weight: 400;
    margin-bottom: 34px;
}

.description_list {
    ::v-deep p {
        margin-bottom: 24px;
    }

    ::v-deep h2, ::v-deep h3, ::v-deep h4 {
        font-weight: 400;
        margin-bottom: 16px;
    }

    ::v-deep ul {
        margin: 0;
        margin-bottom: 34px;
    }

    ::v-deep li {
        list-style: disc;
        margin-left: 17px; // fallback for browsers that don't support calc
        margin-left: calc(24px - 7px); // the margin-left: 24px of its parent element minus the disk width of 7px
    }
}

// .list-ul {
//   padding: 0 $global-spacing-unit-small;
//   margin: 0;
//   font-family: $font-stack-lidl;
//   list-style-position: outside;
// }
// ::v-deep br {
//   display: block;
//   margin: $global-spacing-unit-small 0;
//   content: " ";
// }
// li {
//   color: $color-brand-primary;
//   margin: $global-spacing-unit-tiny 0;
//   list-style-position: outside;
// }
// .list-li span {
//   color: $color-brand-primary-lighter;
// }
</style>

