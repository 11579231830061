<script>
  import { colorPropTypes } from '@/prop-types/color'

  export default {
    name: 'AtomTextBody',
    props: {
      isCenter: Boolean,
      isBold: Boolean,
      isSmall: Boolean,
      isTiny: Boolean,
      isInline: {
        type: Boolean,
        description: 'Returns a <strong>span</strong> instead of a <strong>p</strong>'
      },
      isDiv: {
        type: Boolean,
        description: 'Returns a <strong>div</strong> instead of a <strong>p</strong>'
      },
      isArticle: {
        type: Boolean,
        description: 'Returns an <strong>article</strong> instead of a <strong>p</strong>'
      },
      color: colorPropTypes.color('AtomTextBody')
    },
    render: function (createElement) {
      /*
      We need to dynamically render the HTML tag like p or span, but this can only be done by using the createElement
      method.

      See: https://vuejs.org/v2/guide/render-function.html
       */
      let tag = 'p'

      if (this.isDiv) {
        tag = 'div'
      } else if (this.isInline) {
        tag = 'span'
      } else if (this.isArticle) {
        tag = 'article'
      }

      return createElement(
        `${tag}`,
        {
          class: [
            'body',
            {
              'body--centered': this.isCenter,
              'body--isBold': this.isBold,
              'body--small': this.isSmall,
              'body--tiny': this.isTiny,
              [`body--${this.color}`]: this.color
            }
          ]
        },
        this.$slots.default || this.name
      )
    }
  }
</script>

<style lang="scss" scoped>
  em {
    font-weight: 600;
  }


  // For /deep/ usage see: https://vue-loader.vuejs.org/guide/scoped-css.html#deep-selectors
  .body {
    @include font-size(15px);

    font-weight: 400;
      font-family: $font-stack-lidl;
    line-height: 1.5;
    letter-spacing: 0.5px;

    ::v-deep strong,
    ::v-deep b {
      font-weight: $font-weight-semi-bold;
    }

    ::v-deep a {
      color: inherit;
    }

    ::v-deep ul,
    ::v-deep ol {
      margin-top: $global-spacing-unit-small;
    }
  }

  .body--centered {
    text-align: center;
  }

  .body--isBold {
    font-weight: 600;
  }

  .body--small {
    @include font-size(12px);
  }

  .body--tiny {
    @include font-size(11px)
  }

  @each $name, $color in $color-ui {
    .body--#{$name} {
      color: $color;
    }
  }
</style>
