
import { deepFreeze } from '@/assets/scripts/utils/deep-freeze'

/*
How to create an icon:

1. Export the shape from Sketch as code (probably the group with all the paths): "Copy SVG Code"
2. Paste markup into SVGOMG to clean up the code: https://jakearchibald.github.io/svgomg/
3. Only copy the path definition and check the fill rule
4. Set the correct width and height (viewBox will be filled automatically)
 */

const originalBoxSize = 22

export const getSvgDimensions = ({ width, height, boxSize, isExact }) => {
  if (isExact) {
    return {
      width,
      height,
      viewBox: `0 0 ${width} ${height}`
    }
  }

  return {
    width: boxSize,
    height: boxSize,
    viewBox: `${(width - originalBoxSize) / 2} ${(height - originalBoxSize) / 2} ${originalBoxSize} ${originalBoxSize}`
  }
}

export const ICONS = deepFreeze({
  'arrow-down': {
    width: 12,
    height: 8,
    path: 'M9.988.658L6.05 4.408 2.112.658a1 1 0 0 0-1.38 0l-.021.02a.938.938 0 0 0 0 1.358L6.05 7.132l5.34-5.096a.938.938 0 0 0 0-1.357l-.022-.02a1 1 0 0 0-1.38-.001z',
    fillRule: 'evenodd'
  },
  'arrow-left': {
    width: 7,
    height: 12,
    path: 'M0 5.582L5.002.287a.916.916 0 0 1 1.332 0l.018.02a1 1 0 0 1 0 1.372L2.674 5.582l3.68 3.903a1 1 0 0 1-.001 1.373l-.018.019a.916.916 0 0 1-1.332 0L0 5.582z',
    fillRule: 'evenodd'
  },
  'arrow-right': {
    width: 7,
    height: 12,
    path: 'M6.625 5.582l-5.002 5.295a.916.916 0 0 1-1.332 0l-.018-.019a1 1 0 0 1 0-1.373l3.679-3.903-3.68-3.903A1 1 0 0 1 .273.306L.291.287a.916.916 0 0 1 1.332 0l5.002 5.295z',
    fillRule: 'evenodd'
  },
  'arrow-up': {
    width: 12,
    height: 7,
    path: 'M6.05 0L.71 5.096a.938.938 0 0 0 0 1.358l.022.02a1 1 0 0 0 1.38 0l3.938-3.75 3.938 3.75a1 1 0 0 0 1.38 0l.021-.02a.938.938 0 0 0 0-1.358L6.05 0z',
    fillRule: 'evenodd'
  },
  'check': {
    width: 18,
    height: 14,
    path: 'M16.986 1.707A.999.999 0 1 0 15.572.293L5.018 10.847l-3.31-3.31A.999.999 0 1 0 .292 8.951l3.968 3.97.048.05a.993.993 0 0 0 .851.282.988.988 0 0 0 .614-.334L16.986 1.707z',
    fillRule: 'evenodd'
  },
  'car': {
    width: 19,
    height: 15,
    path: 'M5 6h2.67v3H5V6zm4.67 0h3.01l1.29 3h-4.3V6zM3 5v5a2.99 2.99 0 0 0-.76 2v1.98c-.16.38-.24.8-.24 1.23a3.46 3.46 0 0 0 3.5 3.42A3.48 3.48 0 0 0 8.9 16H14a3.48 3.48 0 0 0 3.4 2.63c1.93 0 3.5-1.53 3.5-3.42 0-.75-.25-1.44-.66-2V12a3 3 0 0 0-3-3h-1.1l-1.88-4.4a1 1 0 0 0-.92-.6H4a1 1 0 0 0-1 1zm1.24 7a1 1 0 0 1 1-1h12a1 1 0 0 1 .99.9 3.5 3.5 0 0 0-4.1 2.1H8.77a3.5 3.5 0 0 0-4.53-1.97V12zm1.26 4.63c-.88 0-1.5-.68-1.5-1.42 0-.73.62-1.41 1.5-1.41S7 14.48 7 15.2c0 .74-.62 1.42-1.5 1.42zm13.4-1.42c0 .74-.62 1.42-1.5 1.42-.87 0-1.5-.68-1.5-1.42 0-.73.63-1.41 1.5-1.41.88 0 1.5.68 1.5 1.41z',
    fillRule: 'evenodd'
  },
  'new-file': {
    width: 14,
    height: 18,
    path: 'M8 0v.03H2a2 2 0 0 0-2 2v14c0 1.1.9 2 2 2h10a2 2 0 0 0 2-2v-10h.03L8 0zM7 5.03c0 1.1.9 2 2 2h3v8.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5v-13c0-.28.22-.5.5-.5H7v3zM6 11H5a1 1 0 0 0 0 2h1v1a1 1 0 0 0 2 0v-1h1a1 1 0 0 0 0-2H8v-1a1 1 0 1 0-2 0v1z',
    fillRule: 'evenodd'
  },
  'print': {
    width: 18,
    height: 16,
    path: 'M5 0a1 1 0 0 0-1 1v2H2a2 2 0 0 0-2 2v6c0 1.1.9 2 2 2v2a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-1-1H5zm8.29 14H4.7c-.39 0-.71-.24-.71-.55v-4.9c0-.3.32-.55.71-.55h8.58c.39 0 .71.24.71.54v4.91c0 .3-.32.55-.71.55zM2.5 5a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1z',
    fillRule: 'evenodd'
  },
  'video': {
    width: 19,
    height: 12,
    path: 'M10.98 2H3.02C2.46 2 2 2.45 2 3v6c0 .55.46 1 1.02 1h7.96c.56 0 1.02-.45 1.02-1V3c0-.55-.46-1-1.02-1zM3 0h8a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3zm13.53 2.36A1.5 1.5 0 0 1 19 3.5v4.36A1.5 1.5 0 0 1 16.53 9l-1-.85A1.5 1.5 0 0 1 15 7.01V4.35c0-.44.2-.86.53-1.14l1-.85z',
    fillRule: 'evenodd'
  },
  'arrows': {
    width: 16,
    height: 16,
    path: 'M1.91.27a.9.9 0 0 0-1.27 0 .92.92 0 0 0 0 1.28l6.19 6.26-6.57 6.64a.92.92 0 0 0 0 1.28.9.9 0 0 0 1.28 0L8.1 9.1l4.22 4.25-1.5 1.47a.27.27 0 0 0 .16.45l4.7.71c.19.03.34-.13.32-.3l-.72-4.63a.27.27 0 0 0-.47-.15l-1.2 1.18-4.23-4.27 4.03-4.08 1.4 1.38c.16.15.43.07.47-.15L16 .33a.27.27 0 0 0-.32-.3l-4.7.7a.27.27 0 0 0-.15.46l1.3 1.27L8.1 6.52 1.91.27z',
    fillRule: 'evenodd'
  },
  'card': {
    width: 18,
    height: 14,
    path: 'M2 0a2 2 0 0 0-2 2v10c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm.5 2a.5.5 0 0 0-.5.5V4h14V2.5a.5.5 0 0 0-.5-.5h-13zM2 11.5V7h14v4.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5z',
    fillRule: 'evenodd'
  },
  'playlist': {
    width: 18,
    height: 14,
    path: 'M1 0a1 1 0 1 0 0 2h8a1 1 0 1 0 0-2H1zm10.71 0l5.7 1.4c.34.1.59.4.59.76v1.51c0 .5-.48.88-.98.76L13.3 3.5v7.39c0 1.72-1.41 3.11-3.15 3.11A3.13 3.13 0 0 1 7 10.89a3.13 3.13 0 0 1 4.71-2.7V0zM0 4a1 1 0 0 1 1-1h8a1 1 0 1 1 0 2H1a1 1 0 0 1-1-1zm1 2a1 1 0 1 0 0 2h5a1 1 0 1 0 0-2H1z',
    fillRule: 'evenodd'
  },
  'home': {
    width: 18,
    height: 18,
    path: 'M1 18h16l.1-9.3h.3a1 1 0 0 0 .65-1.75L16.1 5.27V3.71a1 1 0 0 0-2-.14L10.24.24A1 1 0 0 0 8.97.21L.39 6.92A1 1 0 0 0 1 8.71h.1L1 18zM14.92 6.9c.12.1.18.24.18.38v8.22a.5.5 0 0 1-.5.5H13v-4a1 1 0 0 0-1-1h-2a1 1 0 0 0-1 1l.1 4H3.5a.5.5 0 0 1-.5-.5v-8c0-.15.07-.3.2-.4l6.03-4.56a.5.5 0 0 1 .63.02l5.06 4.34z',
    fillRule: 'evenodd'
  },
  'flag': {
    width: 14,
    height: 16,
    path: 'M1 0a1 1 0 0 0-1 1v14a1 1 0 0 0 2 0v-5h4v1a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H8V2a1 1 0 0 0-1-1H2a1 1 0 0 0-1-1zm1 8h4V3H2v5zm6 2h4V5H8v5z',
    fillRule: 'evenodd'
  },
  'link': {
    width: 17,
    height: 18,
    path: 'M5.83 2.3a1 1 0 0 0-1.42 0L2.3 4.4a1 1 0 0 0 0 1.42l1.42 1.41a1 1 0 1 1-1.42 1.42L.88 7.24A3 3 0 0 1 .88 3L3 .88a3 3 0 0 1 4.24 0l4.25 4.24a3 3 0 0 1 0 4.24l-1.42 1.42a1 1 0 0 1-1.41-1.42l1.41-1.41a1 1 0 0 0 0-1.41L5.83 2.29zm2.88 5a1 1 0 0 1 0 1.4l-1.42 1.42a1 1 0 0 0 0 1.42l4.25 4.24a1 1 0 0 0 1.41 0l2.12-2.12a1 1 0 0 0 0-1.42l-1.41-1.41a1 1 0 1 1 1.41-1.42l1.42 1.42a3 3 0 0 1 0 4.24l-2.13 2.12a3 3 0 0 1-4.24 0l-4.24-4.24a3 3 0 0 1 0-4.24l1.41-1.42a1 1 0 0 1 1.42 0z',
    fillRule: 'evenodd'
  },
  'image': {
    width: 18,
    height: 14,
    path: 'M16 2.5a.5.5 0 0 0-.5-.5h-13a.5.5 0 0 0-.5.5v9c0 .28.22.5.5.5h13a.5.5 0 0 0 .5-.5v-9zM2 0h14a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2C0 .9.9 0 2 0zm10.5 7a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm-7.71-.45L3 10.13V11h12l-1.6-1.87a1 1 0 0 0-1.02-.34L9 9.63 6.47 6.4a1 1 0 0 0-1.68.16z',
    fillRule: 'evenodd'
  },
  'lock': {
    width: 16,
    height: 18,
    path: 'M12 3v1.67A7 7 0 0 1 9 18H7A7 7 0 0 1 4 4.67V3a3 3 0 0 1 3-3h2a3 3 0 0 1 3 3zM7 2h2a1 1 0 0 1 1 1v1.07A7.02 7.02 0 0 0 9 4H7c-.34 0-.67.02-1 .07V3a1 1 0 0 1 1-1zm2 4a5 5 0 0 1 0 10H7A5 5 0 0 1 7 6h2zm1 4a2 2 0 1 0-3 1.73V13a1 1 0 0 0 2 0v-1.27A2 2 0 0 0 10 10z',
    fillRule: 'evenodd'
  },
  'map': {
    width: 14,
    height: 20,
    path: 'M6.7 18.05h.01zm.82-.73c.65-.7 1.37-1.72 2.05-2.9C11.03 11.9 12 9.18 12 7.53 12 4.24 9.84 2 7 2 4.1 2 2 4.15 2 7.51c0 1.73.96 4.46 2.43 6.97a16.5 16.5 0 0 0 2.04 2.85c.19.21.37.38.52.5.16-.12.34-.3.53-.5zM7 20c-2.14 0-7-7.87-7-12.49C0 2.9 3.13 0 7 0s7 3.06 7 7.51C14 11.97 9.14 20 7 20zm0-10a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm0-2a1 1 0 1 1 0-2 1 1 0 0 1 0 2z',
    fillRule: 'evenodd'
  },
  'gift': {
    width: 18,
    height: 18,
    path: 'M16 3c0 .35-.06.69-.17 1H16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6c0-1.1.9-2 2-2h4.17A3 3 0 0 1 11 .76 2.99 2.99 0 0 1 16 3zm-6 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM6.3 7.8L8.08 6H2.5a.5.5 0 0 0-.5.5V10h14V6.5a.5.5 0 0 0-.5-.5h-2.09l1.8 1.8-1.42 1.4-3.04-3.04-3.04 3.05-1.42-1.42zM16 12H2v3.5c0 .28.22.5.5.5h13a.5.5 0 0 0 .5-.5V12zm-4-9a1 1 0 1 0 2 0 1 1 0 0 0-2 0z',
    fillRule: 'evenodd'
  },
  'arrows-vertical': {
    width: 12,
    height: 18,
    path: 'M4.4 9.33a.67.67 0 1 1-1.34 0V2.55L1.14 4.47a.67.67 0 0 1-.95-.94L3.72 0l3.53 3.53a.66.66 0 0 1-.94.94L4.4 2.55v6.78zm2.66-.66a.67.67 0 1 1 1.33 0v6.78l1.92-1.92a.67.67 0 0 1 .94.94L7.72 18 4.2 14.47a.66.66 0 0 1 .95-.94l1.92 1.92V8.67z',
    fillRule: 'evenodd'
  },
  'important-round': {
    width: 18,
    height: 18,
    path: 'M9 18a9 9 0 0 1-9-9 9 9 0 0 1 9-9 9 9 0 0 1 9 9 9 9 0 0 1-9 9zm0-2A7 7 0 1 0 9 2a7 7 0 0 0 0 14zm1-3a1 1 0 0 1-2 0V8a1 1 0 0 1 2 0v5zM8 5a1 1 0 1 1 2 0 1 1 0 0 1-2 0z',
    fillRule: 'evenodd'
  },
  'refresh': {
    width: 16,
    height: 20,
    path: 'M2.374 4.151a.5.5 0 0 0 0 .832l5.598 4.065a.5.5 0 0 0 .778-.416V5.65A6 6 0 1 1 2.03 13.39c-.148-.466-.556-.822-1.045-.822H.944c-.601 0-1.071.53-.913 1.11A8 8 0 1 0 8.75 3.628V.501a.5.5 0 0 0-.778-.416L2.374 4.151z',
    fillRule: 'evenodd'
  },
  'person': {
    width: 16,
    height: 18,
    path: 'M5.7 9A6 6 0 0 0 0 15c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2 6 6 0 0 0-6-6H5.7zm-.35-2A3.99 3.99 0 0 0 12 4a4 4 0 1 0-6.65 3zm8.15 8a.5.5 0 0 0 .5-.5V14c0-2.2-1.8-3-4-3H6c-2.2 0-4 .8-4 3v.5c0 .28.22.5.5.5h11zM8 6a2 2 0 1 0 0-4 2 2 0 0 0 0 4z',
    fillRule: 'evenodd'
  },
  'code': {
    width: 18,
    height: 11,
    path: 'M4.02.65L0 5.49l4.02 4.85a.84.84 0 0 0 1.19.1c.36-.29.4-.82.1-1.18L2.2 5.5l3.13-3.76c.3-.36.25-.9-.11-1.2a.84.84 0 0 0-1.19.12zm8.66 1.08l3.13 3.76-3.13 3.77a.84.84 0 1 0 1.3 1.08L18 5.49 13.98.65a.84.84 0 0 0-1.19-.11c-.36.3-.4.83-.1 1.19z',
    fillRule: 'evenodd'
  },
  'close': {
    width: 14,
    height: 14,
    path: 'M12.97 1.7A1 1 0 1 0 11.56.3L6.63 5.21 1.71.29A1 1 0 1 0 .29 1.71l4.93 4.92-4.93 4.93a1 1 0 1 0 1.42 1.41l4.92-4.92 4.93 4.92a1 1 0 1 0 1.41-1.41L8.05 6.63l4.92-4.92z',
    fillRule: 'evenodd'
  },
  'time': {
    width: 18,
    height: 18,
    path: 'M9 0a9 9 0 1 0 0 18A9 9 0 0 0 9 0zm0 16.2A7.2 7.2 0 1 1 9 1.8a7.2 7.2 0 0 1 0 14.4zm1.32-6.81a1 1 0 0 1-.32-.73V5a1 1 0 1 0-2 0v4.56a1 1 0 0 0 .32.74l2.37 2.18a1 1 0 0 0 1.36-1.47L10.33 9.4z',
    fillRule: 'evenodd'
  },
  'ball': {
    width: 18,
    height: 18,
    path: 'M15 12.6a6.97 6.97 0 0 1-.3-7.67 6.97 6.97 0 0 1 .3 7.66zm-1.3 1.58a6.97 6.97 0 0 1-9.07.3 8.96 8.96 0 0 0-.02-10.94 6.97 6.97 0 0 1 8.66-.09 8.96 8.96 0 0 0 .44 10.73zM2 9c0-1.47.45-2.83 1.23-3.96a6.97 6.97 0 0 1 .01 7.94A6.97 6.97 0 0 1 2 9zm7 9A9 9 0 1 0 9 0a9 9 0 0 0 0 18z',
    fillRule: 'evenodd'
  },
  'star': {
    width: 18,
    height: 18,
    path: 'M5.7 17.08a2 2 0 0 1-2.96-2.17l.86-3.76L.7 8.6a2 2 0 0 1 1.13-3.5l3.84-.33 1.5-3.56a1.99 1.99 0 0 1 3.66 0l1.52 3.56 3.84.33c.52.04 1 .29 1.34.68a2 2 0 0 1-.2 2.82l-2.92 2.54.87 3.76c.12.51.04 1.04-.23 1.5a1.98 1.98 0 0 1-2.73.67l-3.31-2-3.3 2zm7.37-2.87l-.89-3.79 2.94-2.54a.5.5 0 0 0-.29-.88l-3.86-.33-1.5-3.58a.5.5 0 0 0-.93 0L7.03 6.67 3.17 7a.5.5 0 0 0-.29.88l2.94 2.54-.89 3.8a.5.5 0 0 0 .75.54L9 12.75l3.32 2a.5.5 0 0 0 .75-.54z',
    fillRule: 'evenodd'
  },
  'quote': {
    width: 18,
    height: 14,
    path: 'M9.47 4.26a4.26 4.26 0 1 1 8.53 0c0 1.27-.54 2.62-1.62 4.06l-3 5a1 1 0 0 1-1.38.35l-.72-.43a1 1 0 0 1-.34-1.38l2.04-3.4c-2-.36-3.5-2.1-3.5-4.2zM0 4.26a4.26 4.26 0 1 1 8.53 0c0 1.27-.54 2.62-1.62 4.06l-3.01 5a1 1 0 0 1-1.37.35l-.73-.43a1 1 0 0 1-.34-1.38l2.05-3.4C1.5 8.1 0 6.36 0 4.26z',
    fillRule: 'evenodd'
  },
  'share': {
    width: 18,
    height: 14,
    path: 'M15 6a3 3 0 1 0-2.98-2.63L5.17 6.92a3 3 0 1 0-.01 4.17l6.88 3.44a3 3 0 1 0 .96-1.76l-7.01-3.5a2.9 2.9 0 0 0 0-.52l6.92-3.6c.54.53 1.28.85 2.09.85zm0-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2zM3 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm13 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z',
    fillRule: 'evenodd'
  },
  'call': {
    width: 16,
    height: 16,
    path: 'M.1 2.13A.97.97 0 0 1 .73.76l2.7-.74a.5.5 0 0 1 .58.25l1.85 3.5a.5.5 0 0 1-.14.64l-1.6 1.2a21.32 21.32 0 0 0 6 6L11.3 10a.5.5 0 0 1 .65-.14l3.7 2.02c.2.12.3.36.23.58l-.75 2.61c-.18.61-.87.9-1.44.62-1.62-.82-2.9-1.4-4.64-2.67a32.56 32.56 0 0 1-6.3-6.31A35.9 35.9 0 0 1 .1 2.13z',
    fillRule: 'evenodd'
  },
  'play': {
    width: 15,
    height: 11,
    path: 'M4.7 2.41a1 1 0 0 1 1.6 0l6.52 9.05A.98.98 0 0 1 12 13H-1.01a.98.98 0 0 1-.8-1.54l6.5-9.05z',
    fillRule: 'evenodd'
  },
  'merge': {
    width: 16,
    height: 16,
    path: 'M4.44.44v.9c0 .24-.2.44-.44.44h-.44c-.99 0-1.78.8-1.78 1.78V4A.89.89 0 0 1 0 4v-.44C0 1.59 1.6 0 3.56 0H4c.25 0 .44.2.44.44zM12 1.78a.44.44 0 0 1-.44-.45V.44c0-.24.2-.44.44-.44h.44C14.41 0 16 1.6 16 3.56v.88c0 .25-.2.45-.44.45h-.9a.44.44 0 0 1-.44-.45v-.88c0-.99-.8-1.78-1.78-1.78H12zm-.44 12.89c0-.25.2-.45.44-.45h.44c.99 0 1.78-.8 1.78-1.78v-.88c0-.25.2-.45.45-.45h.89c.24 0 .44.2.44.45v.88c0 1.97-1.6 3.56-3.56 3.56H12a.44.44 0 0 1-.44-.44v-.9zM1.33 11.1c.25 0 .45.2.45.45v.88c0 .99.8 1.78 1.78 1.78H4c.25 0 .44.2.44.45v.89c0 .24-.2.44-.44.44h-.44A3.56 3.56 0 0 1 0 12.44v-.88c0-.25.2-.45.44-.45h.9z',
    fillRule: 'evenodd'
  },
  'send': {
    width: 18,
    height: 17,
    path: 'M6.3 11.1l-.38.28c-.31.23-.72.31-1.1.22l-3.87-.98a1.22 1.22 0 0 1-.3-2.28L15.92.15c.4-.21.9-.2 1.3.04.42.26.66.74.57 1.24l-1.91 11.55c-.06.36-.28.68-.6.86-.3.17-.65.22-.98.14l-3.34-.84-2.35 3.32a1.28 1.28 0 0 1-1.38.5 1.24 1.24 0 0 1-.94-1.2V11.3c0-.07 0-.13.02-.19zm5.96-4.43L8.07 11.5v2.6l2.12-3 .6.15 3.41.86 1.57-9.5-3.51 4.06zm-4.33 5zM2.78 9.22l2.26.57 8.83-6.52L2.78 9.23z',
    fillRule: 'evenodd'
  },
  'people': {
    width: 18,
    height: 14,
    path: 'M4.09 7.31A6 6 0 0 0 0 13v1h14v-1a5.99 5.99 0 0 0-6-6H6a6 6 0 0 0-1.91.31zm.53-2.2a3.11 3.11 0 1 0 4.76-4 3.11 3.11 0 0 0-4.76 4zM11.87 12A4 4 0 0 0 8 9H6a4 4 0 0 0-3.87 3h9.74zM7 4.22A1.11 1.11 0 1 1 7 2a1.11 1.11 0 0 1 0 2.22zm3.28 2c-.33 0-.37-.44-.1-.64a3.1 3.1 0 0 0 0-4.94c-.27-.2-.23-.64.1-.64a3.11 3.11 0 0 1 0 6.22zM15.04 14v-1A6 6 0 0 0 11 7.33l.04-.33a6 6 0 0 1 6 6v1h-2z',
    fillRule: 'evenodd'
  },
  'smile': {
    width: 20,
    height: 20,
    path: 'M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zM6.5 9a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm7 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zM5 12.12a1 1 0 0 1 2-.24c.15 1.3 1.42 2.25 3 2.17a3.12 3.12 0 0 0 3.03-2.3 1 1 0 1 1 1.94.5 5.05 5.05 0 0 1-4.97 3.8c-2.57.1-4.71-1.58-5-3.93z',
    fillRule: 'evenodd'
  },
  'play-round': {
    width: 20,
    height: 20,
    path: 'M12.93 9.2L9.6 6.7a1 1 0 0 0-1.6.8v5a1 1 0 0 0 1.6.8l3.33-2.5a1 1 0 0 0 0-1.6zM10 0a10 10 0 1 0 0 20 10 10 0 0 0 0-20zM2 10a8.01 8.01 0 0 1 16 0 8.01 8.01 0 0 1-16 0z',
    fillRule: 'evenodd'
  },
  'mic': {
    width: 14,
    height: 17,
    path: 'M9.99 7L10 3a3 3 0 1 0-6 0v4a3 3 0 1 0 5.99 0zM7 12.1C4.24 12.1 1.7 10 1.7 7H0c0 3.41 2.72 6.23 6 6.72V16a1 1 0 0 0 2 0v-2.28c3.28-.48 6-3.3 6-6.72h-1.7c0 3-2.54 5.1-5.3 5.1z',
    fillRule: 'evenodd'
  },
  'play-previous': {
    width: 14,
    height: 14,
    path: 'M10.03 7.74a.9.9 0 0 0 0-1.48L1.53.18C.9-.27 0 .17 0 .92v12.16c0 .75.9 1.19 1.54.74l8.5-6.08zM14 1a.94.94 0 0 0-.96-.92.94.94 0 0 0-.96.92v12c0 .52.43.93.96.93s.96-.41.96-.92V1z',
    fillRule: 'evenodd'
  },
  'play-next': {
    width: 14,
    height: 14,
    path: 'M10.03 7.74a.9.9 0 0 0 0-1.48L1.53.18C.9-.27 0 .17 0 .92v12.16c0 .75.9 1.19 1.54.74l8.5-6.08zM14 1a.94.94 0 0 0-.96-.92.94.94 0 0 0-.96.92v12c0 .52.43.93.96.93s.96-.41.96-.92V1z',
    fillRule: 'evenodd'
  },
  'heart': {
    width: 16,
    height: 15,
    path: 'M13.28 6.24c.33-.63.48-1.2.48-1.78 0-1.4-1.02-2.46-2.33-2.46-.75 0-1.5.37-2 .97L7.87 4.83 6.34 2.97c-.5-.6-1.26-.97-2-.97C3.03 2 2 3.06 2 4.46c0 .57.15 1.14.48 1.77.73 1.4 1.86 2.6 5.4 5.92 3.53-3.3 4.67-4.52 5.4-5.91zM6.53.58L7.88 1.7 9.23.58a4.36 4.36 0 0 1 6.53 3.89c0 3.06-2.68 5.56-6.73 9.35l-.47.43a1 1 0 0 1-1.36 0l-.46-.42C2.68 10.03 0 7.53 0 4.46A4.36 4.36 0 0 1 6.53.58z',
    fillRule: 'evenodd'
  },
  'media-image': {
    width: 16,
    height: 16,
    path: 'M4.21 1h10.87c.51 0 .92.41.92.92v10.87c0 .5-.41.91-.92.91H4.21a.92.92 0 0 1-.91-.91V1.92c0-.51.4-.92.91-.92zm6.62 5.04a.37.37 0 0 0-.6 0L8.12 9.2l-.63-.96a.37.37 0 0 0-.61 0L5 11.08h9.16l-3.34-5.04zm-9-1.68a.92.92 0 1 0-1.83 0v9.9A2.75 2.75 0 0 0 2.75 17h9.9a.92.92 0 1 0 0-1.83h-9.9a.92.92 0 0 1-.92-.92v-9.9z',
    fillRule: 'evenodd'
  },
  'notification-empty': {
    width: 14,
    height: 16,
    path: 'M0 13a1 1 0 0 0 1 1h4a2 2 0 1 0 4 0h4a1 1 0 1 0 0-2V7a6 6 0 0 0-5-5.92V1a1 1 0 1 0-2 0v.08A6 6 0 0 0 1 7v5a1 1 0 0 0-1 1zM7 2.94l.67.12A4 4 0 0 1 11 7v5H3V7a4 4 0 0 1 3.33-3.94L7 2.94z',
    fillRule: 'evenodd'
  },
  'seperate': {
    width: 18,
    height: 18,
    path: 'M8 18V0h2v18H8zM6 2v2H2v10h4v2H2a2 2 0 0 1-2-2V4c0-1.1.9-2 2-2h4zm6 14v-2h2v2h-2zm4 0v-2h2a2 2 0 0 1-2 2zm0-4v-2h2v2h-2zm0-4V6h2v2h-2zm0-6a2 2 0 0 1 2 2h-2V2zm-4 2V2h2v2h-2z',
    fillRule: 'evenodd'
  },
  'list': {
    width: 18,
    height: 20,
    path: 'M15.5 2h-13a.5.5 0 0 0-.5.5v15c0 .27.23.5.5.5h13a.5.5 0 0 0 .5-.5v-15a.5.5 0 0 0-.5-.5zm0-2A2.5 2.5 0 0 1 18 2.5v15a2.5 2.5 0 0 1-2.5 2.5h-13A2.5 2.5 0 0 1 0 17.5v-15A2.5 2.5 0 0 1 2.5 0h13zM4 9a1 1 0 0 0 1 1h7a1 1 0 0 0 0-2H5a1 1 0 0 0-1 1zm0 4a1 1 0 0 0 1 1h3a1 1 0 0 0 0-2H5a1 1 0 0 0-1 1zm0-8a1 1 0 0 0 1 1h8a1 1 0 0 0 0-2H5a1 1 0 0 0-1 1z',
    fillRule: 'evenodd'
  },
  'wallet': {
    width: 18,
    height: 17,
    path: 'M16.64 1.65l.2 1.12A2 2 0 0 1 18 4.6v10a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-10A2 2 0 0 1 .77 3a2 2 0 0 1 1.25-.78l12.3-2.2a2 2 0 0 1 2.32 1.62zM16 5.09a.5.5 0 0 0-.5-.5h-13a.5.5 0 0 0-.5.5v9c0 .27.22.5.5.5h13a.5.5 0 0 0 .5-.5v-9zm-4 6.5a2 2 0 1 1 0-4 2 2 0 0 1 0 4z',
    fillRule: 'evenodd'
  },
  'important': {
    width: 4,
    height: 17,
    path: 'M0 15a2 2 0 1 1 4 0 2 2 0 1 1-4 0zm0-6V2a2 2 0 1 1 4 0v7a2 2 0 1 1-4 0z',
    fillRule: 'evenodd'
  },
  'media-video': {
    width: 16,
    height: 16,
    path: 'M3.3.92C3.3.4 3.7 0 4.2 0h10.87c.51 0 .92.41.92.92v10.87c0 .5-.41.91-.92.91H4.21a.92.92 0 0 1-.91-.91V.92zm8.36 5.15L8.78 3.8a.55.55 0 0 0-.9.43v4.55c0 .46.54.71.9.43l2.88-2.27a.55.55 0 0 0 0-.87zM1.83 3.36a.92.92 0 1 0-1.83 0v9.9A2.75 2.75 0 0 0 2.75 16h9.9a.92.92 0 1 0 0-1.83h-9.9a.92.92 0 0 1-.92-.92v-9.9z',
    fillRule: 'evenodd'
  },
  'loop': {
    width: 16,
    height: 16,
    path: 'M16 3.11l-2.41 2.44a.4.4 0 0 1-.69-.29V3.83H2v3.43a1 1 0 1 1-2 0V3.83c0-1.1.9-2 2-2h10.9V.4a.4.4 0 0 1 .69-.28l2.4 2.43a.4.4 0 0 1 0 .56zm.2 9.06a2 2 0 0 1-2 2H3.3v1.43a.4.4 0 0 1-.68.28L.2 13.45a.4.4 0 0 1 0-.56l2.4-2.43a.4.4 0 0 1 .69.28v1.43h10.9V8.75a1 1 0 0 1 2 0v3.42z',
    fillRule: 'evenodd'
  },
  'instagram': {
    width: 18,
    height: 18,
    path: 'M13.03 0H4.97A4.97 4.97 0 0 0 0 4.97v8.06A4.97 4.97 0 0 0 4.97 18h8.06A4.97 4.97 0 0 0 18 13.03V4.97A4.97 4.97 0 0 0 13.03 0zm3.37 13.03a3.37 3.37 0 0 1-3.37 3.37H4.97a3.37 3.37 0 0 1-3.37-3.37V4.97A3.37 3.37 0 0 1 4.97 1.6h8.06a3.37 3.37 0 0 1 3.37 3.37v8.06zM9 4.3a4.72 4.72 0 1 0 .01 9.43A4.72 4.72 0 0 0 9 4.3zm0 7.8A3.1 3.1 0 1 1 9 5.9a3.1 3.1 0 0 1 0 6.2zm5.14-9.52a1.3 1.3 0 0 0-1.28 1.29 1.3 1.3 0 0 0 1.28 1.28c.34 0 .67-.14.91-.37a1.3 1.3 0 0 0 0-1.82 1.3 1.3 0 0 0-.9-.38z',
    fillRule: 'evenodd'
  },
  'alarm': {
    width: 18,
    height: 17,
    path: 'M8.65 14.67a5.8 5.8 0 0 0 5.73-5.86 5.8 5.8 0 0 0-5.73-5.86A5.8 5.8 0 0 0 2.9 8.81a5.8 5.8 0 0 0 5.74 5.86zm0 1.95A7.73 7.73 0 0 1 1 8.81 7.73 7.73 0 0 1 8.65 1c4.22 0 7.65 3.5 7.65 7.81a7.73 7.73 0 0 1-7.65 7.81zM17.1 4.19a.94.94 0 0 1-1.35 0l-2.47-2.52a1 1 0 0 1 0-1.38.94.94 0 0 1 1.35 0L17.1 2.8a1 1 0 0 1 0 1.38zM4.1.3a1 1 0 0 1 0 1.38L1.63 4.19a.94.94 0 0 1-1.35 0 1 1 0 0 1 0-1.38L2.75.29a.94.94 0 0 1 1.35 0zm4.21 5.38c0-.37-.29-.67-.65-.67-.37 0-.66.3-.66.67V9.4l2.82 2.8c.25.26.67.26.93 0a.68.68 0 0 0 0-.95L8.3 8.77v-3.1z',
    fillRule: 'evenodd'
  },
  'plus': {
    width: 14,
    height: 14,
    path: 'M8 6V1a1 1 0 0 0-2 0v5H1a1 1 0 0 0 0 2h5v5a1 1 0 0 0 2 0V8h5a1 1 0 0 0 0-2H8z',
    fillRule: 'evenodd'
  },
  'comment': {
    width: 18,
    height: 16,
    path: 'M0 2C0 .9.9 0 2 0h14a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2H8.22L6 15a1.5 1.5 0 0 1-2.5-1.12V13H2a2 2 0 0 1-2-2V2zm2.5 0h13c.28 0 .5.22.5.5v8a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-8c0-.28.22-.5.5-.5zM4 5a1 1 0 0 0 1 1h8a1 1 0 0 0 0-2H5a1 1 0 0 0-1 1zm1 2a1 1 0 0 0 0 2h5.14a1 1 0 0 0 0-2H5z',
    fillRule: 'evenodd'
  },
  'upload': {
    width: 22,
    height: 22,
    path: 'M12 2v.025H6a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-10h.025L12 2zm-1 5.025a2 2 0 0 0 2 2h3v8.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 .5-.5H11v3zM10 13H9a1 1 0 0 0 0 2h1v1a1 1 0 0 0 2 0v-1h1a1 1 0 0 0 0-2h-1v-1a1 1 0 0 0-2 0v1z',
    fillRule: 'evenodd'
  },
  'music': {
    width: 14,
    height: 18,
    path: 'M13.24 1.81L6 0v10.54A4 4 0 1 0 8 14V4.5l4.76 1.19A1 1 0 0 0 14 4.72V2.78a1 1 0 0 0-.76-.97z',
    fillRule: 'evenodd'
  },
  'clipboard': {
    width: 16,
    height: 20,
    path: 'M11.02 3c0 .351-.06.687-.17 1H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h3.191a3 3 0 1 1 5.829-1zM2 6.5a.5.5 0 0 1 .5-.5h1.52v1.5a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5V6h1.48a.5.5 0 0 1 .5.5v11a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11zM8.02 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2z',
    fillRule: 'evenodd'
  },
  'dots': {
    width: 16,
    height: 4,
    path: 'M2 0a2 2 0 0 0-2 2c0 1.1.9 2 2 2a2 2 0 0 0 2-2 2 2 0 0 0-2-2zm12 0a2 2 0 0 0-2 2c0 1.1.9 2 2 2a2 2 0 0 0 2-2 2 2 0 0 0-2-2zM8 0a2 2 0 0 0-2 2c0 1.1.9 2 2 2a2 2 0 0 0 2-2 2 2 0 0 0-2-2z',
    fillRule: 'evenodd'
  },
  'tv': {
    width: 16,
    height: 4,
    path: 'M0 2C0 .9.9 0 2 0h14a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2h-6v1h2a1 1 0 0 1 0 2H6a1 1 0 0 1 0-2h2v-1H2a2 2 0 0 1-2-2V2zm16 .5a.5.5 0 0 0-.5-.5h-13a.5.5 0 0 0-.5.5v7c0 .28.22.5.5.5h13a.5.5 0 0 0 .5-.5v-7z',
    fillRule: 'evenodd'
  },
  'graph': {
    width: 18,
    height: 18,
    path: 'M0 3a3 3 0 0 1 3-3h12a3 3 0 0 1 3 3v12a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3zm3-1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H3zm9 4a1 1 0 0 1 2 0v7a1 1 0 0 1-2 0V6zM9 8a1 1 0 0 1 1 1v4a1 1 0 0 1-2 0V9a1 1 0 0 1 1-1zm-4 3a1 1 0 0 1 1 1v1a1 1 0 0 1-2 0v-1a1 1 0 0 1 1-1z',
    fillRule: 'evenodd'
  },
  'attach': {
    width: 16,
    height: 16,
    path: 'M12.03 2.1c-.4 0-.86.16-1.32.7a201.75 201.75 0 0 0-5 6.12c-.14.18-.14.31-.13.4 0 .1.07.24.2.36.12.12.25.17.34.17.07 0 .15-.01.24-.12l3.3-3.86a.95.95 0 0 1 1.4-.08c.4.39.44 1.05.08 1.49L7.8 11.17c-1.08 1.22-2.56.85-3.37.06a3.32 3.32 0 0 1-.82-1.64c-.09-.7.1-1.4.57-2.02 1.25-1.6 2.5-3.13 5.05-6.17A3.6 3.6 0 0 1 12.06 0c.98.01 1.88.46 2.55 1.1 1.31 1.24 2.09 3.61.5 5.51-.91 1.1-1.61 1.9-2.34 2.72-1.06 1.2-2.18 2.47-4.13 4.86A4.55 4.55 0 0 1 5.05 16a4.92 4.92 0 0 1-3.34-1.43 6.05 6.05 0 0 1-1.68-3.45A5.76 5.76 0 0 1 1.38 6.9l3.58-4.45.65-.81.13-.16.03-.05a.95.95 0 0 1 1.4-.13c.42.37.47 1.03.12 1.48l-.01.01-.03.04-.13.17-.66.82-3.58 4.45a3.58 3.58 0 0 0-.89 2.63c.07.88.42 1.52 1.05 2.11.58.56 1.31.88 2.03.89.68 0 1.42-.29 2.07-1.09 2.01-2.46 3.16-3.76 4.21-4.94.73-.83 1.4-1.6 2.29-2.65.61-.74.46-1.8-.34-2.55a1.92 1.92 0 0 0-1.27-.57z',
    fillRule: 'evenodd'
  },
  'progress': {
    width: 18,
    height: 18,
    path: 'M0 9a9 9 0 1 1 18 0A9 9 0 0 1 0 9zm5.25.56a.8.8 0 1 0-1.2 1.07L6 12.78c.32.36.9.4 1.27.07l6.61-5.96a.8.8 0 1 0-1.07-1.18l-6.1 5.48-1.46-1.63z',
    fillRule: 'evenodd'
  },
  'scissor': {
    width: 18,
    height: 18,
    path: 'M10 15.55A2.48 2.48 0 0 1 7.5 18C6.12 18 5 16.9 5 15.55v-.82c0-1.07.7-1.98 1.67-2.32v-1.77H5.59a2.46 2.46 0 0 1-2.32 1.63h-.81a2.45 2.45 0 1 1 0-4.9h.81c.66 0 1.3.2 1.95.4.48.16.96.3 1.45.38V3.8c0-1.4.72-2.7 1.92-3.45L9.17 0v8.18H18l-.36.57a4.1 4.1 0 0 1-3.45 1.89H9.17v.19c0 .65.2 1.3.41 1.95.21.64.42 1.29.42 1.95v.82zm-1.67-.82v.82c0 .45-.37.81-.83.81a.83.83 0 0 1-.83-.81v-.82c0-.45.37-.82.83-.82.46 0 .83.37.83.82zM3.27 9h-.81a.82.82 0 1 0 0 1.64h.81a.82.82 0 0 0 0-1.64z',
    fillRule: 'evenodd'
  },
  'grid': {
    width: 18,
    height: 18,
    path: 'M1 0h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1zm8 0h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1zm0 8h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1zM1 8h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1z',
    fillRule: 'evenodd'
  },
  'undo': {
    width: 16,
    height: 16,
    path: 'M.5 3.6a1 1 0 0 0 0 1.73l5 3.47A1 1 0 0 0 7 7.93V5.47h3a4 4 0 1 1 0 8H3a1 1 0 1 0 0 2h7a6 6 0 0 0 0-12H7V1A1 1 0 0 0 5.5.13L.5 3.6z',
    fillRule: 'evenodd'
  },
  'tag': {
    width: 15,
    height: 15,
    path: 'M8.46 2L2.2 8.25l4.54 4.54L13 6.54V2H8.46zM7.1 13.14H7.1zM1.84 7.87v.01zm-1.4-.69L7.2.43A1.5 1.5 0 0 1 8.25 0h5.25c.82 0 1.5.68 1.5 1.5v5.25c0 .41-.16.79-.44 1.07L7.8 14.55a1.5 1.5 0 0 1-2.12 0L.44 9.3A1.47 1.47 0 0 1 0 8.25c0-.41.17-.8.44-1.07zM10.5 6a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z',
    fillRule: 'evenodd'
  },
  'twitter': {
    width: 17,
    height: 14,
    path: 'M17 1.63c-.63.28-1.3.47-2 .55A3.5 3.5 0 0 0 16.53.25c-.68.4-1.42.7-2.22.85A3.48 3.48 0 0 0 8.28 3.5c0 .27.03.54.1.8A9.9 9.9 0 0 1 1.17.63a3.49 3.49 0 0 0 1.08 4.65 3.49 3.49 0 0 1-1.58-.44v.05a3.5 3.5 0 0 0 2.8 3.42 3.53 3.53 0 0 1-1.57.06 3.49 3.49 0 0 0 3.25 2.42A7 7 0 0 1 0 12.24a9.86 9.86 0 0 0 5.35 1.57c6.41 0 9.92-5.31 9.92-9.92l-.01-.45A6.96 6.96 0 0 0 17 1.64z',
    fillRule: 'evenodd'
  },
  'volume': {
    width: 16,
    height: 12,
    path: 'M6 2.98L3.6 4.86H2v3.13h1.6L6 9.87v-6.9zM5.58.77A1.5 1.5 0 0 1 8 1.95v8.95a1.5 1.5 0 0 1-2.42 1.18L2.9 9.99H2a2 2 0 0 1-2-2V4.86c0-1.1.9-2 2-2h.9L5.58.76zM10.54 0a6.5 6.5 0 0 1 0 12.83.47.47 0 0 1-.54-.48v-1.02c0-.25.2-.46.44-.52a4.5 4.5 0 0 0 0-8.8.55.55 0 0 1-.44-.52V.48c0-.29.25-.52.54-.47zM10 9.05V7.9c0-.2.12-.38.28-.5a1.25 1.25 0 0 0 0-1.95c-.16-.13-.28-.3-.28-.5V3.8c0-.3.28-.54.56-.44a3.25 3.25 0 0 1 0 6.14c-.28.1-.56-.13-.56-.44z',
    fillRule: 'evenodd'
  },
  'zoom': {
    width: 18,
    height: 18,
    path: 'M10.9 9.48a6 6 0 1 0-1.42 1.41l.95.96a2 2 0 0 0 .52 1.93l3.44 3.44a2 2 0 1 0 2.83-2.83l-3.44-3.44a2 2 0 0 0-1.93-.52l-.96-.95zm-2.07-6.3a4 4 0 1 1-5.65 5.65 4 4 0 0 1 5.65-5.66z',
    fillRule: 'evenodd'
  },
  'calendar': {
    width: 18,
    height: 17,
    path: 'M2 1a1 1 0 0 1 2 0v1h10V1a1 1 0 0 1 2 0v1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4c0-1.1.9-2 2-2V1zm0 6v7a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1zm11 4a1 1 0 1 1 0 2 1 1 0 0 1 0-2z',
    fillRule: 'evenodd'
  },
  'view': {
    width: 20,
    height: 14,
    path: 'M10 0C5.45 0 1.57 2.83 0 6.82a10.75 10.75 0 0 0 20 0C18.43 2.82 14.55 0 10 0zm0 11.36a4.55 4.55 0 1 1 0-9.1 4.55 4.55 0 0 1 0 9.1zm0-7.27a2.72 2.72 0 1 0 0 5.45 2.72 2.72 0 0 0 0-5.45z',
    fillRule: 'evenodd'
  },
  'view-disabled': {
    width: 21,
    height: 17,
    path: 'M1.83 10.08C1.06 9.18.44 8.15 0 7.04A10.75 10.75 0 0 1 13.17.7L10.9 2.58a4.56 4.56 0 0 0-5.44 4.5l-3.62 3zM18.72 0l1.4 1.66-2.3 1.93c.93 1 1.67 2.16 2.18 3.45a10.75 10.75 0 0 1-13.62 6.19l-4.06 3.42-1.53-1.82L18.72 0zM8.76 4.34l-1.45 1.2c.31-.56.83-1 1.45-1.2zm-.16 7.02a4.54 4.54 0 0 0 5.9-4.97l-5.9 4.97z',
    fillRule: 'evenodd'
  },
  'file': {
    width: 22,
    height: 22,
    path: 'M12 2v.025H6a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-10h.025L12 2zm-1 5.025v-3H6.5a.5.5 0 0 0-.5.5v13a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5v-8.5h-3a2 2 0 0 1-2-2z',
    fillRule: 'evenodd'
  },
  'settings': {
    width: 18,
    height: 18,
    path: 'M.348 5.368L1.455 3.55c.774-1.271 2.282-1.327 3.204-.823l.178.098c.222-.144.453-.278.689-.402v-.034C5.526 1.167 6.49 0 7.893 0h2.214c1.402 0 2.367 1.167 2.367 2.389v.034c.236.124.467.258.689.402l.178-.098c.922-.504 2.43-.448 3.204.823l1.107 1.818c.829 1.363.037 2.771-.918 3.294l-.196.107.006.231a9.263 9.263 0 0 1-.006.231l.196.107c.955.523 1.747 1.931.918 3.294l-1.107 1.818c-.774 1.271-2.282 1.328-3.204.823l-.178-.098a8.723 8.723 0 0 1-.69.402v.034c0 1.222-.964 2.389-2.366 2.389H7.893c-1.402 0-2.367-1.167-2.367-2.389v-.034a8.723 8.723 0 0 1-.69-.402l-.177.098c-.922.505-2.43.448-3.204-.823L.348 12.632c-.83-1.363-.037-2.771.918-3.294l.196-.107A9.263 9.263 0 0 1 1.456 9c0-.075.003-.153.006-.231l-.196-.107C.31 8.139-.481 6.731.348 5.368zm4.674-.162L3.7 4.482c-.196-.107-.438-.059-.54.109L2.054 6.409c-.102.168-.026.39.17.498l1.396.764a5.395 5.395 0 0 0 0 2.658l-1.396.764c-.196.108-.272.33-.17.498l1.107 1.818c.102.168.344.216.54.109l1.32-.724a5.564 5.564 0 0 0 2.503 1.456v1.361a.38.38 0 0 0 .369.389h2.214a.38.38 0 0 0 .369-.389V14.25a5.564 5.564 0 0 0 2.502-1.456l1.32.724c.197.107.439.059.54-.109l1.108-1.818c.102-.168.026-.39-.17-.498l-1.396-.764a5.395 5.395 0 0 0 0-2.658l1.396-.764c.196-.108.272-.33.17-.498l-1.107-1.818c-.102-.168-.344-.216-.54-.109l-1.321.724a5.553 5.553 0 0 0-2.502-1.455V2.389a.38.38 0 0 0-.37-.389H7.894a.38.38 0 0 0-.37.389v1.362c-.964.26-1.823.77-2.501 1.455zM11.992 9c0 1.657-1.341 3-2.996 3A2.998 2.998 0 0 1 6 9c0-1.657 1.341-3 2.996-3a2.998 2.998 0 0 1 2.996 3zM9.995 9a1 1 0 1 1-1.999.001A1 1 0 0 1 9.995 9z',
    fillRule: 'evenodd'
  },
  'pen': {
    width: 15,
    height: 15,
    path: 'M13.641 5.086a1 1 0 0 1-.014 1.4L5.652 14.39H.692c-.27.016-.622-.362-.637-.62L0 8.843 7.97.828a1 1 0 0 1 1.414 0l.015.015.55-.55a1 1 0 0 1 1.414 0l2.829 2.828c.39.391.39 1.024 0 1.415l-.55.55zM2.313 9.314l2.828 2.828 6.364-6.364L8.677 2.95 2.313 9.314z',
    fillRule: 'evenodd'
  },
  'folder-active': {
    width: 22,
    height: 22,
    path: 'M12.41 6H18a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.59a1 1 0 0 1 .7.3l1.42 1.4a1 1 0 0 0 .7.3z',
    fillRule: 'evenodd'
  },
  'folder': {
    width: 22,
    height: 22,
    path: 'M4 6v11h14V8h-5.586a3 3 0 0 1-2.121-.879L9.172 6H4zm8.414 0H18a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.586a1 1 0 0 1 .707.293l1.414 1.414a1 1 0 0 0 .707.293z',
    fillRule: 'evenodd'
  },
  'menu': {
    width: 15,
    height: 15,
    path: 'M1 0h12a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1zm0 6h12a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1zm0 6h12a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1v-1a1 1 0 0 1 1-1z',
    fillRule: 'evenodd'
  },
  'upload-cloud': {
    width: 20,
    height: 13,
    path: 'M4.8 3A5 5 0 0 0 0 8a5.11 5.11 0 0 0 4 5h12c2.25-.25 4-2.18 4-4.5A4.5 4.5 0 0 0 15.66 4 6 6 0 0 0 4.8 3zm11.07 8H4.23A3.13 3.13 0 0 1 2 8a3 3 0 0 1 2.88-3l1.1-.04.55-.96a4 4 0 0 1 7.24.67l.46 1.28 1.36.05A2.5 2.5 0 0 1 18 8.5c0 1.27-.94 2.32-2.13 2.5zm-5.85-6.85a.5.5 0 0 0-.72 0L7.14 6.43a.5.5 0 0 0 .36.85h1.16v1.5a1 1 0 0 0 2 0v-1.5h1.15a.5.5 0 0 0 .36-.85l-2.15-2.28z',
    fillRule: 'evenodd'
  },
  'visibility': {
    width: 19,
    height: 12,
    path: 'M9.094 0C3.969 0 1.038 3.542.187 5.38L0 5.784l.175.409C1.005 8.136 3.924 12 9.094 12c5.171 0 8.09-3.864 8.92-5.807l.175-.409-.187-.404C17.151 3.542 14.22 0 9.094 0zM4.918 8.749C3.585 7.87 2.684 6.699 2.214 5.82c.484-.823 1.424-1.939 2.83-2.753A4.982 4.982 0 0 0 4.094 6c0 1.016.303 1.961.824 2.749zm8.353 0c1.333-.879 2.234-2.05 2.704-2.929-.484-.823-1.424-1.939-2.831-2.753.598.824.95 1.837.95 2.933a4.967 4.967 0 0 1-.823 2.749zM12.094 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z',
    fillRule: 'evenodd'
  },
  'download': {
    width: 11,
    height: 16,
    path: 'M5.54 0a1 1 0 0 1 1 1v8.25l2.63-2.5a1.16 1.16 0 0 1 1.58 0c.43.42.43 1.09 0 1.5L5.54 13 .33 8.25a1.03 1.03 0 0 1 0-1.5 1.16 1.16 0 0 1 1.58 0l2.63 2.5V1a1 1 0 0 1 1-1zM1.1 14h8.8c.6 0 1.1.45 1.1 1s-.5 1-1.1 1H1.1C.5 16 0 15.55 0 15s.5-1 1.1-1z',
    fillRule: 'evenodd'
  },
  'mail': {
    width: 18,
    height: 12,
    path: 'M2 0h14a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2C0 .9.9 0 2 0zm14 2.5a.5.5 0 0 0-.5-.5h-13a.5.5 0 0 0-.5.5v9c0 .28.22.5.5.5h13a.5.5 0 0 0 .5-.5v-9zM9 9.15l-1.26-.89-2.1 1.2a.91.91 0 0 1-.91-1.58l1.35-.77-1.46-1.02a.9.9 0 1 1 1.02-1.47L9 6.95l3.36-2.33a.9.9 0 0 1 1.02 1.47L12 7.06l1.32.82a.9.9 0 1 1-.96 1.54l-1.99-1.24L9 9.15z',
    fillRule: 'evenodd'
  },
  'trash': {
    width: 16,
    height: 18,
    path: 'M5 3V2c0-1.1.9-2 2-2h2a2 2 0 0 1 2 2v1h4a1 1 0 0 1 0 2v10a3 3 0 0 1-3 3H4a3 3 0 0 1-3-3V5a1 1 0 0 1 0-2h4zm2 0h2V2H7v1zM3 5v10a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V5H3z',
    fillRule: 'evenodd'
  },
  'filter': {
    width: 16,
    height: 17,
    path: 'M4.41500971 7.3065693c.04088401.040146.06132602.120438.06132602.180657v6.1423357c0 .4817519.28618807.9233577.75635418 1.1040146L9.3210909 16.399635c.143094.060219.3066301.100365.4701661.100365.2453041 0 .4701661-.060219.6745862-.2007299.3475141-.2208029.5519341-.6021898.5519341-1.0036497V7.4872263c0-.060219.020442-.120438.061326-.180657l4.108843-5.05839412c.3475141-.42153284.4088401-.98357664.1635361-1.46532846C15.1061783.30109489 14.6155702 0 14.0636361 0H1.43047698C.87854285 0 .38793473.30109489.14263067.78284672c-.24530406.48175182-.16353604 1.04379562.16353604 1.46532846l4.108843 5.05839412zM13.613912 1.60583942L9.791257 6.30291971c-.265746.34124087-.4088401.74270069-.4088401 1.18430659v7.1861314l-3.27072077-1.3248176V7.4872263c0-.4215329-.14309404-.84306572-.4088401-1.18430659L1.88020109 1.60583942H13.613912z',
    fillRule: 'evenodd'
  }
})

export const ICON_TYPES = Object.keys(ICONS).sort()
