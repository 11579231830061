<script>

  const slotConfig = {
    iconLeft: {
      name: 'iconLeft',
    },
    iconRight: {
      name: 'iconRight',
    }
  }

  export default {
    name: 'AtomButton',
    props: {
      href: {
        type: String,
        description: 'Returns an <strong>a</strong> instead of a <strong>button</strong>'
      },
      isExternalLink: {
        type: Boolean,
        description: 'Adds a target="_blank", but only when the href prop is also provided'
      },
      toName: {
        type: [String, Object],
        description: 'Returns an <strong>router-link</strong> component instead of a <strong>button</strong>'
      },
      toParams: {
        type: Object
      },
      hasHover: Boolean,
      hasUnderline: Boolean,
      isSecondary: Boolean,
      isGhost: Boolean,
      isDisabled: Boolean,
      isSmall: Boolean,
      isTiny: Boolean,
      isFullWidth: Boolean,
      isOrange: Boolean,
      isGrey: Boolean
    },
    slotConfig,
    render: function (createElement) {
      /*
      We need to dynamically render the HTML tag like p or span, but this can only be done by using the createElement
      method.

      See: https://vuejs.org/v2/guide/render-function.html
       */
      let tag = 'button'
      if (this.href) {
        tag = 'a'
      } else if (this.toName) {
        tag = 'router-link'
      }

      const iconLeft = this.$slots.iconLeft
      const iconRight = this.$slots.iconRight
      const textElement = createElement(
        'span',
        {
          attrs: {
            class: 'button__text'
          }
        },
        this.$slots.default || this.name
      )

      const buttonElements = [
        textElement
      ]

      if (iconLeft) {
        buttonElements.unshift(createElement(
          'span',
          {
            attrs: {
              class: 'button__icon-left'
            }
          },
          iconLeft
        ))
      }

      if (iconRight) {
        buttonElements.push(createElement(
          'span',
          {
            attrs: {
              class: 'button__icon-right'
            }
          },
          iconRight
        ))
      }

      return createElement(
        tag,
        {
          class: [
            'button',
            {
              'button--has-hover': this.hasHover,
              'button--has-underline': this.hasUnderline,
              'button--primary': !this.isSecondary && !this.isGhost,
              'button--secondary': this.isSecondary,
              'button--ghost': this.isGhost,
              'button--small': this.isSmall,
              'button--tiny': this.isTiny,
              'button--full-width': this.isFullWidth,
              'button--orange': this.isOrange,
              'button--grey': this.isGrey
            }
          ],
          attrs: {
            disabled: this.isDisabled,
            href: this.href,
            target: (this.isExternalLink && this.href) ? '_blank' : null,
            to: this.toName ? { name: this.toName, params: this.toParams } : null
          }
        },
        buttonElements
      )
    }
  }
</script>

<style lang="scss" scoped>
  .button {
    @include font-size(15px);
    @include reset-button();

    display: flex;
    justify-content: center;

    text-decoration: none;
    min-width: 160px;
    min-height: 48px;
    font-weight: 600;
    border: 1px solid transparent;
    font-family: $font-stack-lidl;
    outline: 0;
    padding: $global-spacing-unit-tiny $global-spacing-unit-small;
    transition: $global-transition;
    border-radius: $global-radius;
      width: 100%;

  }

  .button--primary {
    background-color: #fff000;
      color: $color-brand-primary-darker;

    &:hover,
    &:active {
      background-color: $color-brand-primary-lighter;

      & svg path {
        fill: $color-palette-white !important;
      }
    }
  }

  .button--secondary {
    background-color: transparent;
    color: $color-brand-primary;
    border: $color-brand-primary 1px solid;

    &:hover,
    &:active {
      color: $color-brand-primary-darker;
    }
  }

  .button--ghost {
    border-color: none;
    color: $color-palette-black;

    &:hover,
    &:active {
      background-color: none;
      color: $color-brand-primary;
    }
  }

  .button--isActive {
    background-color: $color-palette-white;
    color: $color-brand-primary;
  }

  .button--small {
    @include font-size(15px);

    min-height: 34px;
    min-width: auto;
    padding: $global-spacing-unit-tiny $global-spacing-unit-small;
  }

  .button--tiny {
    @include font-size(15px);
    min-height: 25px;
    min-width: auto;
    padding: 2px 8px;
  }

  .button--full-width {
    display: block;
    width: 100%;
  }

  .button[disabled] {
    background-color: $color-palette-grey;
    pointer-events: none;

    &.button--ghost {
      border-color: none;
      background-color: transparent;
      color: $color-palette-grey;
    }
  }

  .button--secondary[disabled] {
    background-color: transparent;
    color: $color-palette-grey;
    border-color: $color-palette-grey;
  }
.button__text{
  align-self: center;
}
  .button__icon-left {
    margin-right: $global-spacing-unit-small;
  }

  .button__icon-right {
    margin-left: $global-spacing-unit-small;
  }

</style>
