import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueLazyload from 'vue-lazyload'
import VueGtag from "vue-gtag";

Vue.config.productionTip = false

Vue.use(VueAxios, axios)

Vue.use(VueGtag, {
  config: { id: "G-W37LRFDCF6" }
});

Vue.use(VueLazyload, {
  lazyComponent: true
})
new Vue({
  render: h => h(App),
}).$mount('#app')
