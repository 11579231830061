<template>
  <div id="app">
    <header class="header">
      <section class="header_topbar">
        <nav>
          <ul>
            <li><a target="_blank" rel="external" href="https://www.werkenbijlidl.nl/jouw-sollicitatie/contact">Contact</a></li>
          </ul>
        </nav>
      </section>
      <section class="header_title">
        <AtomTextHeading
          :level="1"
          class="header_title_text"
          color="brand-primary-darker"
          isLarge
          isBrokenUp
        >
          {{ title }}
        </AtomTextHeading>
        <!-- <AtomTextBody color="brand-primary-lighter"> </AtomTextBody> -->
      </section>
    </header>
    <main class="main-holder">
      <section class="search-wrapper">
        <div class="search-bar">
          <form v-on:submit.prevent>
            <AtomFormInputText
              :value="searchValue"
              @change="setSearchValue"
              @iconCLick="searchVacatures"
              @pressEnter="searchVacatures"
              hasIconRight
              icon="zoom"
              iconColor="brand-primary-lighter"
              name="input-1"
              placeholder="Wat voor baan zoek je?"
            />
          </form>
        </div>
        <div @click="popupOpenFilterFunction" class="filter">
          <AtomIcon
            color="white"
            type="filter"
            width="48"
            height="48"
          ></AtomIcon>
        </div>
      </section>

      <PopUpFilter
        :jobsData="jobsData"
        @goBack="popupClose"
        @updateData="updateData"
        v-show="popupOpenFilter"
      />
      <AtomTextHeading :level="2" class="joboffer-wrapper__heading">
       <span class="u-text-visuallyHidden">Overzicht:</span> {{ getJobsAmount }} vacatures gevonden
      </AtomTextHeading>

      <div class="desktop-joboffer-apply-wrapper">

        <section
          :class="{ show: popupOpenOffer || popupOpenFilter }"
          class="joboffer-wrapper"
        >

          <template v-if="(filteredData.results === false && jobsData.length > 0)">
            <MoleculeJobOffer
              :intern="job.type"
              :location="job.stad"
              :time="job.deeltijdfactor"
              :is-highlighted="job.highlight"
              :is-active="jobOfferIsActive(job.id)"
              :title="job.titel"
              @click.native="popupOpenOfferFunction(job, $event)"
              class="fadein-card"
              v-for="(job, index) in jobsData"
              v-if="index < looping"
              :key="job.name"
              :this-data="job"
            ></MoleculeJobOffer>
          </template>

          <template
            v-else-if="
              (filteredData.data.length > 0 && filteredData.results === true && jobsData.length > 0)
            "
          >
            <MoleculeJobOffer
              :intern="job.type"
              :location="job.stad"
              :time="job.deeltijdfactor"
              :is-highlighted="job.highlight"
              :title="job.titel"
              :is-active="jobOfferIsActive(job.id)"
              @click.native="popupOpenOfferFunction(job, $event)"
              v-for="(job, index) in filteredData.data"
              :key="job.id"
              :this-data="job"
            />
          </template>

          <div
            class="noResults"
            v-else-if="
              filteredData.data.length === 0 ||
              (filteredData.results === false && jobsData.length === 0)
            "
          >
<!--            <AtomTextHeading>-->
<!--              {{ filteredData.data.length }}-->
<!--              Geen resultaat-->
<!--            </AtomTextHeading>-->
          </div>
        </section>

        <popUpOffer
          :job="clickedJob"
          @goBack="popupClose"
          v-if="popupOpenOffer"
        />
      </div>

      <!--      <popUpApply-->
      <!--          :job="clickedJob"-->
      <!--          @goBack="popupClose"-->
      <!--          v-if="popupOpenOffer"-->
      <!--      />-->
    </main>
  </div>
</template>

<script>
// Performance
import popUpApply from "./components/presentational/organisms/popUpApply";
const AtomButton = () => import("./components/presentational/atoms/AtomButton");
const AtomTextHeading = () =>
  import("./components/presentational/atoms/AtomTextHeading");
const AtomTextBody = () =>
  import("./components/presentational/atoms/AtomTextBody");
const AtomFormInputText = () =>
  import("./components/presentational/atoms/AtomFormInputText");
const AtomIcon = () => import("./components/presentational/atoms/AtomIcon");
const MoleculeJobOffer = () =>
  import("./components/presentational/molecules/MoleculeJobOffer");
const PopUpFilter = () =>
  import("./components/presentational/organisms/popUpFilter");
const PopUpOffer = () =>
  import("./components/presentational/organisms/popUpOffer");

export default {
  name: "app",
  props: {
    title: {
      type: String,
      default: "Interne vacaturebank",
    },
    titleText: {
      type: String,
      default:
        "Hier komt een korte tekst met uitleg over de vacatures en wat voor soort werk je kunt doen binnen lidl",
    },
  },
  components: {
    popUpApply,
    PopUpOffer,
    PopUpFilter,
    MoleculeJobOffer,
    AtomIcon,
    AtomFormInputText,
    AtomTextBody,
    AtomTextHeading,
    AtomButton,
  },
  data: function () {
    // Oke hij rendered al de HTML voor dat de data binnen is.
    return {
      popupOpenFilter: false,
      popupOpenOffer: false,
      looping: 20,
      // The link data is now in the info
      offersData: [], // Dit is je original data
      jobsData: [], // Dit  zijn de data jobs
      // filteredData.data: [], // Dit  zijn de data jobs
      filteredData: {
        data: [],
        results: false,
      },
      // Pasta?
      clickedJob: null, // click hier pak je de this data van een job
      searchValue: "", // dit is je search value
    };
  },
  methods: {
    preventDefault: function (event) {
      event.preventDefault();
    },
    scroll() {

      if (window.innerWidth > 900) {
        const element = document.querySelector('.joboffer-wrapper')
        element.onscroll = () => {
          if (element.scrollHeight - element.scrollTop === element.clientHeight) {
            this.looping = this.looping + 20;
            // element is at the end of its scroll, load more content
          }
        }
      } else {
        window.onscroll = () => {
          let bottomChange = document.documentElement.offsetHeight;
          let betweenBottom =
              Math.max(
                  window.pageYOffset,
                  document.documentElement.scrollTop,
                  document.body.scrollTop
              ) + window.innerHeight;

          if (bottomChange < betweenBottom + 75) {
            this.looping = this.looping + 20;
            // element is at the end of its scroll, load more content
          }
        }
      }
    },
    updateData: function (newData) {
      this.filteredData.data = newData;
      this.filteredData.results = true;
    },
    popupOpenFilterFunction: function () { // only relevant for mobile devices
      this.popupOpenFilter = true;
    },
    popupOpenOfferFunction: function (value, event) {
      // event param is used for the gtag to filter out the user events
      this.clickedJob = value;
      this.popupOpenOffer = true;

      if (event) {
        this.$gtag.event(`user_opened_vacancy_${value.type}`, {
          'event_category' : 'user_events',
          'event_label' : `${value.titel}`,
          'value' : `1`
        })
      }
    },
    popupClose: function () {
      this.popupOpenFilter = false;
      this.popupOpenOffer = false;
    },

    searchVacatures: function (search) {
      search.event.target.blur();
      this.filteredData.results = true;
      let data = this.jobsData;
      let searchValue = search.value.toLowerCase();
      let filtered = [];

      data.forEach(function (key) {
        if (key.titel && key.titel.toLowerCase().includes(searchValue)) {
          filtered.push(key);
        }
      });
      this.filteredData.data = filtered;

      this.$gtag.event('user_search_text_field', {
        'event_category' : 'user_events',
        'event_label' : `${searchValue}`,
        'value' : `1`
      })
    },

    setSearchValue: function (value) {
      if (value.value || value.value === "") {
        this.searchValue = value.value;
      }
    },
    openFirstOffer: function () {
      if (window.innerWidth > 900) {
        this.popupOpenOfferFunction(this.jobsData[0])
      }
    },
    jobOfferIsActive: function (id) {
      return this.clickedJob?.id === id
    }
  },
  computed: {
    getJobsAmount: function () {
      if (this.filteredData.results === true) {
        return this.filteredData.data.length;
      } else if (this.jobsData) {
        return this.jobsData.length;
      } else {
        return 0;
      }
    },
  },
  mounted() {
    this.$gtag.event('app_mounted', {
      'event_category' : 'app_status',
      'event_label' : 'app_mounted',
      'value' : '1',
      'non_interaction': true
    })
    this.scroll();
    this.axios
      .get("data/v2seuhbdfisdh.json")
      .then((response) => {
        this.offersData = response;
        this.jobsData = response.data;

        // sort by date posted
        function compareType( a, b ) {
          if ( a.type !== 'intern' ){
            return -1;
          }
          else {
            return 1;
          }
          return 0;
        }

        // sort by date posted
        function compareDate( a, b ) {
          if ( a.post_datum < b.post_datum ){
            return -1;
          }
          if ( a.post_datum > b.post_datum ){
            return 1;
          }
          return 0;
        }


        this.jobsData = this.jobsData.map(job => {
          job.post_datum = new Date(job.post_datum.slice(0, 10))
          return job
        })

        this.jobsData.sort( compareType )
        this.jobsData.sort( compareDate ).reverse()

        this.openFirstOffer()

        this.$gtag.event('app_data_loaded', {
          'event_category' : 'app_status',
          'event_label' : 'app_data_loaded',
          'value' : '1',
          'non_interaction': true
        })

      })
      .catch((err) => {
        this.$gtag.event('app_data_loaded_failed', {
          'event_category' : 'app_status',
          'event_label' : 'app_data_loaded_failed',
          'value' : '1',
          'non_interaction': true
        })
        console.error("err", err);
      });
  },
};
</script>

<style lang="scss">
* {
  box-sizing: border-box;
  scroll-behavior: smooth;
  transition: all 0.3s ease;

}

body {
  margin: 0;
  overflow-x: hidden;
  background-color: #eef3f5;
}

#app {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header {
  background-color: $color-brand-primary-lighter;
  background: linear-gradient(
    90deg,
    rgba(0, 83, 155, 0.32) 0%,
    rgba(0, 83, 155, 0) 100%
  );
  background-image:  image-set(
          "./assets/img/lidl_header_background_mobile.webp" type("image/webp"),
          "./assets/img/lidl_header_background_mobile.png" type("image/png")
  );
  background-repeat: repeat;
  background-position: bottom;
  background-size: auto 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 359px;
  padding-left: 24px;
  padding-top: 27px;
  padding-bottom: 64px;
  //margin-bottom: 24px;

  @media (min-width: 900px) {
    background-image:  image-set(
            "./assets/img/lidl_header_background_desktop.webp" type("image/webp"),
            "./assets/img/lidl_header_background_desktop.png" type("image/png")
    );
    padding-bottom: 120px;
    height: 480px;
    padding-left: 0;
    padding-right: 0;

    & > * {
        margin: 0 auto;
      //  margin-bottom: 50px;
        padding: 24px 24px 0 24px;
        width: 100%;
        max-width: 1134px;
    }
  }
}

.header_topbar {
  nav ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;

    li {
      list-style: none;

      a {
        text-shadow: 1px 1px 1px #525252;
        text-decoration: none;
        font-family: $font-headings;
        font-weight: $font-weight-semi-bold;
        font-size: 16px;
        line-height: 19px;
        color: $color-palette-white;

        margin-right: 21px;
      }
    }
  }

}

.header_title {
  width: 100%;
}

.header .header_title_text {
  display: flex;
  flex-direction: column;

  span {
    font-family: $font-stack-lidl;
    font-weight: $font-weight-semi-bold;
    font-size: 32px;
    line-height: 38px;
    vertical-align: middle;

    height: 42px;
    width: fit-content;
    padding: 2px 8px;
    margin-bottom: 12px;

    color: #000000;
    background-color: #fff000;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  @media (min-width: 500px) {
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 auto;
  }

  @media (min-width: 900px) {
    span {
      margin-bottom: 0;
    }
  }
}

.main-holder {
  width: 100vw;
  max-width: 500px;
  margin: 0 auto;
  padding: 24px 24px 0 24px;
  background-color: $color-palette-white;

  @media (min-width: 900px) {
    max-width: 1134px;
    align-self: center;
    background-color: unset;
  }
}

.input {
  border: 1px solid $color-brand-primary-lighter;
}

.search-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  @media (min-width: 900px) {
    display: none; // differs from the mobile version in HTML-structure..
  }
}

.search-bar {
  width: 100%;
  max-width: 553px;
}

form input.input {
  height: 48px;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #8b979e;
}

.filter {
  height: 48px;
  width: 48px;
  align-items: center;
  display: flex;
  justify-content: center;
  background-color: $color-brand-primary;
  padding: 12px;
  margin-left: 9px;
  border-radius: 2px;
  cursor: pointer;

  @media (min-width: 900px) {
    display: none;
  }
}

h2.joboffer-wrapper__heading {
  margin-bottom: 16px;
  font-weight: 600;
}

.desktop-joboffer-apply-wrapper {
  @media (min-width: 900px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 7px;
    margin-bottom: 32px;

    .joboffer-wrapper, .popUpOfferWrapper {
      max-height: 80vh;
      overflow-y: auto;
    }

  }
}

.show {
  overflow: hidden;
  height: 200px;

  @media (min-width: 900px) {
    overflow: unset;
    height: unset;
  }
}

.noResults {
  width: 100%;
  text-align: center;
  font-size: $font-headings;
}

.fadein-card {
  animation: fadein-card;
  animation-duration: 0.75s;
}

@keyframes fadein-card {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}
</style>
