<template>
    <nav class="backButton"
         @click="goBack"
    >
    <li>
        <AtomIcon
                color="brand-primary"
                type="arrow-left"

        ></AtomIcon>
        <AtomTextBody
        >
            Terug
        </AtomTextBody>
        </li>
    </nav>

</template>

<script>
  import AtomIcon from '../atoms/AtomIcon'
  import AtomTextBody from '../atoms/AtomTextBody'

  export default {
    name: 'MoleculeBackButton',
    components: {AtomTextBody, AtomIcon},
    methods:{
      goBack (event) {
        this.$emit('goBack', false)
      },
    }
  }
</script>

<style lang="scss" scoped>
    li, .backButton {
      display: flex;
    }

    .backButton {
        color: $color-brand-primary;
        background-color: $color-palette-white;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        width: 100%;

        /*width: 60px;*/
        cursor: pointer;
        /*position: fixed;*/
        /*top: 0;*/
        z-index:5;
        /*<!--padding: $global-spacing-unit-tiny;-->*/
        margin-bottom: 32px;
    }

    .backButton * {
        align-self: center;
    }

    @media(min-width: 900px) {
     .popUpOffer .backButton {
        display: none;
      }

      .popUpFilter_header .backButton {
        display: none;
      }

      .popUpApply .backButton {
        display: block;
      }
    }
</style>
