var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"selection-wrapper"},[(_vm.hasDrop)?_c('AtomIcon',{staticClass:"dropDownIcon",class:{ spin: _vm.isOpen },attrs:{"color":"brand-primary","type":"arrow-down"}}):_vm._e(),(_vm.label)?_c('label',{staticClass:"selection-wrapper__label",class:{
      'selection-wrapper__label--has-error': _vm.hasError,
      'selection-wrapper__label--has-success': _vm.hasSuccess,
    },attrs:{"for":_vm.id},on:{"click":function($event){return _vm.$emit('labelClick', $event)}}},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('span',{staticClass:"selection-wrapper__input",class:{
      'selection-wrapper__input--circle': _vm.isCircle,
      'selection-wrapper__input--has-error': _vm.hasError,
      'selection-wrapper__input--has-success': _vm.hasSuccess,
      'selection-wrapper__input--has-label': _vm.label,
    }},[_vm._t("default")],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }