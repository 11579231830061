// THIS FILE IS AUTOMATICALLY GENERATED!
// See: runscripts/extract-scss-variables.js

/* eslint-disable */
module.exports = {
  "colorPaletteWhite": "rgb(255, 255, 255)",
  "colorPaletteBlack": "rgb(57, 57, 57)",
  "colorPaletteBlackLighter": "rgba(37, 44, 57, 0.2)",
  "colorPaletteGrey": "rgb(199, 201, 206)",
  "colorPaletteGreyLighter": "rgb(242, 243, 247)",
  "colorBlackText": "rgb(37, 44, 57)",
  "colorPaletteRed": "rgb(254, 23, 45)",
  "colorPaletteGreen": "rgb(67, 176, 42)",
  "colorPaletteOrange": "rgb(237, 139, 0)",
  "colorStatusError": "rgb(254, 23, 45)",
  "colorStatusSuccess": "rgb(67, 176, 42)",
  "colorStatusWarning": "rgb(237, 139, 0)",
  "colorBrandPrimary": "rgb(0, 118, 168)",
  "colorBrandPrimaryLighter": "rgba(0, 131, 249, 0.1)",
  "colorBrandPrimaryDarker": "rgb(0, 95, 136)",
  "colorBrandExample": "rgb(240, 15, 240)",
  "colorBackground": "rgb(255, 255, 255)",
  "colorText": "rgb(37, 44, 57)",
  "colorUi": {
    "white": "rgb(255, 255, 255)",
    "black": "rgb(57, 57, 57)",
    "blackLighter": "rgba(37, 44, 57, 0.2)",
    "grey": "rgb(199, 201, 206)",
    "greyLighter": "rgb(242, 243, 247)",
    "brandPrimary": "rgb(0, 118, 168)",
    "brandPrimaryLighter": "rgba(0, 131, 249, 0.1)",
    "brandPrimaryDarker": "rgb(0, 95, 136)",
    "green": "rgb(67, 176, 42)",
    "red": "rgb(254, 23, 45)",
    "orange": "rgb(237, 139, 0)"
  },
  "inuitConfig": {
    "env": "dev",
    "healthcheck": false,
    "debug": true
  },
  "globalRadius": 4,
  "globalTransition": "all 0.3s ease",
  "mqResponsive": true,
  "mqBreakpoints": {
    "mobile": 400,
    "tablet": 600,
    "laptop": 1280,
    "desktop": 1600,
    "ultra": 1920
  },
  "mqShowBreakpoints": "'mobile', 'tablet', 'laptop', 'desktop', 'ultra'",
  "stackIndex": {
    "navigation": 1,
    "page": 0
  },
  "fontStackRubik": "'Rubik', 'Arial', 'Helvetica Neue', 'Helvetica', sans-serif",
  "fontStackRubikMedium": "'Rubik', 'Arial', 'Helvetica Neue', 'Helvetica', sans-serif",
  "fontWeightNormal": 400,
  "fontWeightSemiBold": 600,
  "fontWeightBold": 700,
  "fontHeadings": "'Rubik', 'Arial', 'Helvetica Neue', 'Helvetica', sans-serif",
  "fontCopy": "'Rubik', 'Arial', 'Helvetica Neue', 'Helvetica', sans-serif",
  "inuitWrapperWidthTiny": 400,
  "inuitWrapperWidthSmall": 752,
  "inuitWrapperWidth": 1024,
  "inuitWrapperWidthLarge": 1140
}
/* eslint-enable */
