<template>
  <AtomFormInputWrapper
      :hasError="hasError"
      :hasFocus="hasFocus"
      :hasSuccess="hasSuccess"
      :id="id"
      :label="label"
      :message="message"
  >
    <div
        :class="{'icon-left': hasIconLeft, 'icon-right': hasIconRight}"
        class="input-container"
        v-if="!isTextarea && !isPassword && !isDate"
    >
      <AtomIcon
          :boxSize="24"
          :color="iconColor"
          :type="icon"
          class="input-icon input-icon--left"
          v-if="hasIconLeft"
      />
      <input
          :class="inputClasses"
          :disabled="isDisabled"
          :id="id"
          :placeholder="placeholder"
          :required="isRequired"
          @blur="toggleFocus(false)"
          @focus="toggleFocus(true)"
          class="input"
          type="email"
          v-if="isEmail"
          v-model="model"
      />

      <input
          :class="inputClasses"
          :id="id"
          :placeholder="placeholder"
          @blur="toggleFocus(false)"
          @focus="toggleFocus(true)"
          class="input"
          type="number"
          v-else-if="isNumber"
          v-model="model"
      />

      <input
          :class="inputClasses"
          :id="id"
          :placeholder="placeholder"
          @blur="toggleFocus(false)"
          @focus="toggleFocus(true)"
          class="input"
          pattern="[0-9]"
          type="number"
          v-else-if="isTel"
          v-model="model"
      />


      <input
          :class="inputClasses"
          :disabled="isDisabled"
          :id="id"
          :placeholder="placeholder"
          :required="isRequired"
          @blur="toggleFocus(false)"
          @focus="toggleFocus(true)"
          @keyup.enter='emitEnter'
          class="input"
          type="text"
          v-else
          v-model="model"
      />
      <AtomIcon
          :boxSize="24"
          :color="iconColor"
          :type="icon"
          @click.native="emitIcon"
          class="input-icon input-icon--right clickAble"
          v-if="hasIconRight"
      />
    </div>


    <textarea
        :class="inputClasses"
        :disabled="isDisabled"
        :id="id"
        :placeholder="placeholder"
        :required="isRequired"
        @blur="toggleFocus(false)"
        @focus="toggleFocus(true)"
        class="input input--textarea"
        v-if="isTextarea"
        v-model="model"
    ></textarea>

    <input
        :class="inputClasses"
        :disabled="isDisabled"
        :id="id"
        :placeholder="placeholder"
        :required="isRequired"
        @blur="toggleFocus(false)"
        @focus="toggleFocus(true)"
        class="input"
        type="date"
        v-if="isDate"
        v-model="model"
    />

    <input
        :class="inputClasses"
        :disabled="isDisabled"
        :id="id"
        :placeholder="placeholder"
        :required="isRequired"
        @blur="toggleFocus(false)"
        @focus="toggleFocus(true)"
        class="input"
        type="password"
        v-if="isPassword"
        v-model="model"
    />
  </AtomFormInputWrapper>
</template>

<script>
  import { formPropTypes } from '@/prop-types/form'

  import { getRandomId } from '@/assets/scripts/utils/random-id'

  import AtomFormInputWrapper from './helpers/AtomFormInputWrapper.vue'
  import AtomIcon from './AtomIcon'

  export default {
    name: 'AtomFormInputText',
    components: {
      AtomIcon,
      AtomFormInputWrapper
    },
    props: {
      isEmail: Boolean,
      isNumber: Boolean,
      isTel: Boolean,
      isDisabled: Boolean,
      isRequired: Boolean,
      hasError: formPropTypes.inputHasError,
      hasSuccess: formPropTypes.inputHasSuccess,
      isPassword: formPropTypes.inputIsPassword,
      isTextarea: formPropTypes.inputIsTextarea,
      isDate: Boolean,
      label: formPropTypes.inputLabel,
      message: formPropTypes.inputMessage,
      name: formPropTypes.inputName,
      placeholder: formPropTypes.inputPlaceholder,
      value: formPropTypes.inputValue,
      hasIconLeft: Boolean,
      hasIconRight: Boolean,
      icon: String,
      iconColor: {
        type: String,
        default: 'brand-primary',
      },
    },
    data: function () {
      return {
        id: getRandomId(),
        hasFocus: false
      }
    },
    computed: {
      model: {
        get: function () {
          return this.value
        },
        set: function (value) {
          this.$emit('change', {
            name: this.name,
            value: value
          })
          this.$emit('input', value)
        }
      },
      inputClasses: function () {
        return {
          'input--has-error': this.hasError,
          'input--has-success': this.hasSuccess
        }
      }
    },
    watch: {
      model (value) {
        this.$emit('change', value)
        this.$emit('input', value)
      }
    },
    methods: {
      toggleFocus: function (value) {
        this.hasFocus = value
      },
      emitEnter: function (event) {
        this.$emit('pressEnter', {
          event: event,
          name: this.name,
          value: this.model
        })
      },
      emitIcon: function (event) {
        this.$emit('iconCLick', {
          event: event,
          name: this.name,
          value: this.model
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .icon-left {
    .input {
      padding-left: 52px !important;
    }
  }

  .icon-right {
    .input {
      padding-right: 52px !important;
    }
  }

  .input-container {
    position: relative;

    .input-icon {
      position: absolute;
      top: 10px;
    }

    .input-icon--left {
      left: $global-spacing-unit-small;
    }

    .input-icon--right {
      right: $global-spacing-unit-small
    }
  }

  .clickAble {
    cursor: pointer;
  }

  .input {
    @include reset-form-element();
    @include font-size(15px);

    background-color: $color-palette-white;
    border: 1px solid $color-palette-grey-lighter;
    border-radius: $global-radius;
    color: $color-palette-grey;
    display: block;
    padding: $global-spacing-unit-tiny $global-spacing-unit-small;
    width: 100%;
    font-family: $font-stack-lidl;
  }

  .input:valid {
    color: $color-palette-black;
  }

  .input:focus {
    color: $color-palette-black;
    border-color: $color-palette-black;
  }

  .input--textarea {
    height: 130px;
  }

  .input--has-error {
    border: 2px solid $color-status-error;
    color: $color-status-error;
  }

  .input--has-success {
    border: 1px solid $color-status-success;
    color: $color-status-success;
  }
</style>
