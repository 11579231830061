<template>
  <div>
    <label
      v-if="label"
      class="inputWrapper__label"
      :class="{ 'inputWrapper__label--has-focus': hasFocus }"
      :for="id"
    >
      {{ label }}
    </label>

    <slot></slot>

    <p
      v-if="message"
      class="inputWrapper__message"
      :class="{
        'inputWrapper__message--has-error': hasError,
        'inputWrapper__message--has-success': hasSuccess,
      }"
    >
      {{ message }}
    </p>
  </div>
</template>

<script>
import { formPropTypes } from "@/prop-types/form";

export default {
  name: "AtomFormInputWrapper",
  props: {
    id: formPropTypes.inputId,
    label: formPropTypes.inputLabel,
    message: formPropTypes.inputMessage,
    hasFocus: formPropTypes.inputHasFocus,
    hasError: formPropTypes.inputHasError,
    hasSuccess: formPropTypes.inputHasSuccess,
  },
};
</script>

<style lang="scss" scoped>
.popUpApply_form_inputWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }

   @media (min-width: 900px) {
      flex-direction: row;
      flex-wrap: wrap;
  }
}

.popUpApply_form_inputApply .inputWrapper__label {
  @media (min-width: 900px) {
    color: #000000;
  }
}

.inputWrapper__label {
  @include font-size(16px);
  line-height: 19px;
  color: $color-palette-grey-darker;
  display: block;
  font-weight: 600;
  transition: $global-transition;
  margin-bottom: 8px;
}

.inputWrapper__label--has-focus {
  color: $color-palette-black;
}

.inputWrapper__message {
  @include font-size(14px);

  text-align: right;
}

.inputWrapper__message--has-error {
  color: $color-status-error;
}

.inputWrapper__message--has-success {
  color: $color-brand-primary;
}
</style>
