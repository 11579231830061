var render, staticRenderFns
import script from "./AtomTextBody.vue?vue&type=script&lang=js&"
export * from "./AtomTextBody.vue?vue&type=script&lang=js&"
import style0 from "./AtomTextBody.vue?vue&type=style&index=0&id=c3a393a0&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c3a393a0",
  null
  
)

export default component.exports