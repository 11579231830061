<script>
import { colorPropTypes } from "@/prop-types/color"

export default {
  name: "AtomTextHeading",
  props: {
    level: {
      type: Number,
      default: 2,
      validator: function (value) {
        return value >= 1 && value <= 6
      },
    },
    isSmall: Boolean,
    isLarge: Boolean,
    isMarked: Boolean,
    isEnormous: Boolean,
    isShort: Boolean,
    isShorter: Boolean,
    isBrokenUp: Boolean,
    isHidden: Boolean,
    color: colorPropTypes.color("AtomTextHeading"),
  },
  render: function (createElement) {
    /*
      We need to dynamically render the HTML tag like h1 or h2, but this can only be done by using the createElement
      method.

      See: https://vuejs.org/v2/guide/render-function.html
       */
    let content

    if (this.isBrokenUp) { // split input text into strings and put into seperate <span> elements for easier styling
      let text = this.$slots.default[0].text
      let words = text.trim().split(" ")
      content = words.map((word) => {
        return createElement("span", word)
      })
    } else {
      content = this.$slots.default || this.name
    }

      return createElement(
        `h${this.level}`,
        {
          class: [
            "heading",
            {
              "heading--small": this.isSmall,
              "heading--large": this.isLarge,
              "heading--Enormous": this.isEnormous,
              "heading--marked": this.isMarked,
              "heading--short": this.isShort,
              "heading--shorter": this.isShorter,
              "heading--hidden": this.isHidden,
              [`heading--${this.color}`]: this.color,
            },
          ],
        },
        content
      )
    }

}
</script>

<style lang="scss" scoped>
.heading {
  // @include font-size(20px);

  // letter-spacing: 0.5px;
  font-family: $font-stack-lidl;
  // font-weight: 600;
}

.heading--large {
  @include font-size(24px);
}

.heading--small {
  @include font-size(15px);
}

.heading--Enormous {
  @include font-size(28px);
}

.heading--short {
  max-width: 440px;
}

.heading--shorter {
  max-width: 350px;
}

.heading--marked {
  background: $color-brand-primary;
  color: $color-palette-white;
  display: inline-block;
  padding: 1px $global-spacing-unit-small;
}


.heading--hidden {
  font-size: 0;
  line-height: 0;
  color: transparent;
}

@each $name, $color in $color-ui {
  .heading--#{$name} {
    color: $color;
  }
}
</style>
