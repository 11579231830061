<template>
  <AtomFormInputWrapper
    :id="id"
    :hasError="hasError"
    :hasSuccess="hasSuccess"
  >
    <AtomFormSelectionWrapper
      :id="hasDrop ? id+1 : id"
      :label="label"
      :isOpen="isOpen"
      :hasError="hasError"
      :hasSuccess="hasSuccess"
      :hasDrop="hasDrop"
      @labelClick="labelClick"
    >
      <input
        type="checkbox"
        :id="id"
        v-model="checked"
      />
      <span
        class="checkbox-icon"
        :class="{ 'checkbox-icon--checked': checked }"
      >
        <AtomIcon
          type="check"
          :boxSize="30"
          v-on:click="labelClick"
        />
      </span>
    </AtomFormSelectionWrapper>
  </AtomFormInputWrapper>
</template>

<script>
  import { formPropTypes } from '@/prop-types/form'
  import { getRandomId } from '@/assets/scripts/utils/random-id'

  import AtomIcon from './AtomIcon.vue'
  import AtomFormInputWrapper from './helpers/AtomFormInputWrapper.vue'
  import AtomFormSelectionWrapper from './helpers/AtomFormSelectionWrapper.vue'

  export default {
    name: 'AtomFormInputCheckbox',
    components: {
      AtomFormInputWrapper,
      AtomFormSelectionWrapper,
      AtomIcon
    },
    props: {
      value: Boolean,
      name: formPropTypes.inputName,
      label: formPropTypes.inputLabel,
      isChecked: formPropTypes.inputIsChecked,
      hasError: formPropTypes.inputHasError,
      hasSuccess: formPropTypes.inputHasSuccess,
      hasDrop: {
        type: Boolean,
        default: false
      },
      isOpen:{
        type: Boolean,
        default: false
      }
    },
    data: function () {
      return {
        id: getRandomId(),
        checkValue: this.isChecked
      }
    },
    computed: {
      checked: {
        get: function () {
          return this.value
        },
        set: function (value) {
          this.$emit('change', {
            name: this.name,
            value
          })
          this.$emit('input', value )
        }
      }
    },
    methods: {
      labelClick: function (value) {
        if (this.hasDrop) {
          this.$emit('openDropDown', {
            name: this.name,
            value
          })
          this.$emit('input', value )
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .checkbox-icon {
    opacity: 0;
    position: absolute;
    z-index: 1;
  }

  .checkbox-icon--checked {
    opacity: 1;

    svg {
      height: 15px;
      width: 15px;
      ::v-deep path {
        fill: $color-brand-primary !important;
      }
    }
  }

</style>
